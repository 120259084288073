import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import Button from "../../../../../../../components/button";
import { useSuperior } from "../../../../../../../components/superior-context";
import { useTranslation } from "../../../../../../../components/translation-context/TranslationContext";
import SuperiorDTO from "../../../../../../../models/SuperiorDTO";
import CompanySuperiorService from "../../../../../../../network/CompanySuperiorService";
import BoxContainer from "../../../../../components/box-container";
import BoxContent from "../../../../../components/box-content";
import BoxHeader from "../../../../../components/box-header";
import Input from "../../../../../components/input";

const customStyles = {
	option: (provided: any) => ({
		...provided,
		fill: "#405364",
		fontWeight: 300,
		fontSize: 15,
		zIndex: 200,
	}),
	control: (provided: any) => ({
		...provided,
		width: "fill",
		border: "none",
		borderRadius: 0,
		borderBottom: `1px solid #929292`,
		boxShadow: "none",
		fontWeight: 300,
		padding: 0,
		"&:hover": {
			borderBottom: `1px solid #929292`,
		},
		zIndex: 200,
	}),
	menu: (provided: any) => ({
		...provided,
		zIndex: 9999,
	}),
	menuPortal: (provided: any) => ({
		...provided,
		zIndex: 9999,
	}),
	dropdownIndicator: (base: any) => ({
		...base,
		color: "#405364",
		transform: "translate(2px)",
	}),
	indicatorSeparator: () => ({}),
	valueContainer: (provided: any) => ({
		...provided,
		padding: 0,
		outline: "none",
		fontWeight: 300,
		zIndex: 200,
	}),
	placeholder: (provided: any) => ({
		...provided,
		color: "#405364",
		fontWeight: 300,
		opacity: 0.4,
		fontSize: 15,
	}),
	singleValue: (provided: any, state: any) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";

		return { ...provided, opacity, transition, fontWeight: 300 };
	},
};

const NewUser = () => {
	// Attributes
	const history = useHistory();
	const { translate } = useTranslation();
	const { superior: currentUser } = useSuperior();
	const [isBusy, setIsBusy] = useState(false);
	const [superior, setSuperior] = useState<SuperiorDTO>({
		firstName: "",
		email: "",
		language: "",
		lastName: "",
		phoneNumber: "",
		id: "",
		companyId: currentUser?.companyId ?? "",
		requestForm: "",
	});

	// Functions
	async function handleSaveClicked() {
		setIsBusy(true);
		const { firstName, lastName, language, email, requestForm } =
			superior;

		// check missing field
		if (
			!firstName ||
			!lastName ||
			!language ||
			!email ||
			!language ||
			!requestForm
		) {
			toast("MissingFields");
		} else {
			await CompanySuperiorService.post(
				superior?.companyId ?? "",
				superior
			);
			toast(translate("NewUserSaved"), {
				position: "bottom-right",
				type: "success",
				style: { background: "#52D8B0" },
				bodyStyle: { color: "white" },
				hideProgressBar: true,
			});

			history.goBack();
		}
		setIsBusy(false);
	}

	function handleSuperiorChanged(event: any) {
		if (event.target) {
			switch (event.target.name) {
				case "email":
					setSuperior({
						...superior,
						email: event.target.value,
					});
					break;
				case "firstName":
					setSuperior({
						...superior,
						firstName: event.target.value,
					});
					break;
				case "lastName":
					setSuperior({
						...superior,
						lastName: event.target.value,
					});
					break;
				case "phoneNumber":
					setSuperior({
						...superior,
						phoneNumber: event.target.value,
					});
					break;
			}
		} else {
			setSuperior({ ...superior, language: event.value });
		}
	}

	function handleRequestFormChanged(option: any) {
		setSuperior({ ...superior, requestForm: option.value });
	}

	return (
		<main
			className="settings-container"
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<BoxContainer>
				<BoxHeader title="userSettings" subTitle="newUser" />
				<BoxContent>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							flex: 1,
							width: "-webkit-fill-available",
						}}
					>
						<div style={{ marginRight: 15, width: "100%" }}>
							<Input
								placeholder="firstNamePlaceholder"
								name="firstName"
								value={superior?.firstName}
								onChange={handleSuperiorChanged}
							/>
						</div>
						<div style={{ marginLeft: 15, width: "100%" }}>
							<Input
								name="lastName"
								placeholder="lastNamePlaceholder"
								value={superior?.lastName}
								onChange={handleSuperiorChanged}
							/>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							flex: 1,
							width: "-webkit-fill-available",
							marginTop: 15,
						}}
					>
						<div style={{ marginRight: 15, width: "100%" }}>
							<Input
								placeholder="phoneNumber"
								name="phoneNumber"
								value={superior?.phoneNumber}
								onChange={handleSuperiorChanged}
								required={false}
							/>
						</div>
						<div style={{ marginLeft: 15, width: "100%" }}>
							<Input
								name="email"
								placeholder="emailPlaceholder"
								value={superior?.email}
								onChange={handleSuperiorChanged}
							/>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							flex: 1,
							width: "-webkit-fill-available",
							marginTop: 15,
						}}
					>
						<div
							style={{
								width: "100%",
								height: 49,
								transform: "translateY(11px)",
								marginRight: 15,
							}}
						>
							<Select
								value={
									superior?.language
										? {
												value: superior.language,
												label: superior.language,
										  }
										: undefined
								}
								onChange={handleSuperiorChanged}
								styles={customStyles}
								name="language"
								placeholder={translate("language")}
								options={[
									{ value: "FR", label: "FR" },
									{ value: "NL", label: "NL" },
									{ value: "EN", label: "EN" },
								]}
							/>
						</div>
						<div
							style={{
								width: "100%",
								height: 49,
								transform: "translateY(11px)",
								marginLeft: 15,
							}}
						>
							<Select
								value={
									superior?.requestForm
										? {
												value: superior.requestForm,
												label: superior.requestForm,
										  }
										: undefined
								}
								onChange={handleRequestFormChanged}
								styles={customStyles}
								name="requestForm"
								placeholder={translate("requestForm")}
								options={[
									{
										value: "InterfaceBasic",
										label: translate("interfaceBasic"),
									},
									{
										value: "InterfaceAdmin",
										label: translate("interfaceAdmin"),
									},
								]}
							/>
						</div>
					</div>

					<Button
						isLoading={isBusy}
						onClick={handleSaveClicked}
						style={{
							marginLeft: "auto",
							marginTop: 30,
							maxWidth: 100,
							background: "#4691CD",
							fontSize: 14,
							color: "white",
							cursor: "pointer",
							padding: "7.5px 15px",
							borderRadius: 8,
						}}
					>
						save
					</Button>
				</BoxContent>
			</BoxContainer>
		</main>
	);
};

export default NewUser;
