import axios from "axios";
import Cookies from "universal-cookie";
import RefreshTokenService from "./RefreshTokenService";

const errors: string[] = [];
const success: string[] = [];

const cookies = new Cookies();

function config() {
	const access_token = cookies.get("ACCESS_TOKEN");
	return { headers: { Authorization: `bearer ${access_token}` } };
}

async function execute(service: Promise<any>, showErrors: boolean = true) {
	try {
		return await service;
	} catch (e: any) {
		if (e?.response?.status == 401) {
			const old_refresh_token = cookies.get("REFRESH_TOKEN");

			cookies.remove("ACCESS_TOKEN");
			cookies.remove("REFRESH_TOKEN");

			if (old_refresh_token) {
				const tokenResponse = await RefreshTokenService.post(old_refresh_token);

				if (tokenResponse) {
					const { access_token, refresh_token } = tokenResponse;

					try {
						await cookies.set("ACCESS_TOKEN", access_token);
						await cookies.set("REFRESH_TOKEN", refresh_token);

						e.config.headers["Authorization"] = `Bearer ${access_token}`;

						return axios.request(e.config);
					} catch {
						cookies.remove("ACCESS_TOKEN");
						cookies.remove("REFRESH_TOKEN");
						window.location.pathname = "/en/sign-up";
					}
				} else {
					window.location.pathname = "/en/sign-up";
				}
			}
			window.location.pathname = "/en/sign-up";
		} else if (showErrors) {
			if (e?.response?.data) {
				if (e?.response?.data.error_description) {
					errors.push(e?.response?.data.error_description);
				} else if(e?.response?.data.message){
					errors.push(e?.response?.data.message);
				} else {
					errors.push(e?.response?.data);
				}
			}
		}
		return undefined;
	}
}
const IAM_URL = process.env.REACT_APP_IAM_SERVER;
const API_URL = process.env.REACT_APP_RESOURCE_SERVER;

export default { errors, execute, config, IAM_URL, API_URL, success };
