import Button from "../../../../../../components/button";
import BoxContent from "../../../../components/box-content";
import BoxContainer from "../../../../components/box-container";
import BoxFooter from "../../../../components/box-footer";
import BoxHeader from "../../../../components/box-header";
import Input from "../../../../components/input";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import InputGroup from "../../../../components/input-group";
import { useForm } from 'react-hook-form';
import Stepper from "../../../../components/stepper";
import IRouteComponentLanguageProps from '../../../../../../interfaces/IRouteComponentLanguageProps';
import PlacesAutoComplete from "../../../../components/places-autocomplete";
import { useRequest } from "../../../../../../components/request-context";

const InvoicingInformation = (props: IRouteComponentLanguageProps) => {

    // Attributes
    const history = useHistory();
    const { language } = props.match.params;
    const {request, setRequest} = useRequest();
    const { register, handleSubmit, errors, setValue, getValues, clearErrors } = useForm();

    // Effects
    useEffect(() => {
        register("firstName", {required: true});
        register("lastName", {required: true});
        register("email", {required: true});
        register("phoneNumber", {required: true});
        register("companyName", {required: true});
        register("companyNumber", {required: true, pattern: {value: /[0-9]{4}\.[0-9]{3}\.[0-9]{3}/g, message: ""}});
        register("companyAddress", {required: true});
    }, []);

    // Functions
    function handleFieldChanged(event: any) {
        clearErrors(event.target.name);
        setValue(event.target.name, event.target.value);
    }

    function handleButtonClicked() {
        setRequest(
            {
                ...request,
                company: { name: getValues('companyName'), companyNumber: getValues('companyNumber'), address: getValues('companyAddress'), language: language },
                superior: { firstName: getValues("firstName"), lastName : getValues("lastName"), email : getValues("email"), phoneNumber : getValues("phoneNumber"), language: language }
            }
        );

        history.push(`/${language}/one-shot/payment`);
    }

    return (
        <BoxContainer>
            <BoxHeader title={"billingInfo"} subTitle={"oneShotCheckRequest"} />
            <BoxContent>
                <InputGroup>
                    <Input value={getValues("firstName")} error={errors.firstName} name="firstName" onChange={handleFieldChanged} placeholder={"firstNamePlaceholder"} />
                    <Input value={getValues("lastName")} error={errors.lastName} name="lastName" onChange={handleFieldChanged} placeholder={"lastNamePlaceholder"} />
                </InputGroup>
                <InputGroup>
                    <Input value={getValues("email")} error={errors.email} name="email" onChange={handleFieldChanged} placeholder={"emailPlaceholder"} />
                    <Input value={getValues("phoneNumber")} error={errors.phoneNumber} name="phoneNumber" onChange={handleFieldChanged} placeholder={"phoneNumberPlaceholder"} />
                </InputGroup>
                <InputGroup>
                    <Input value={getValues("companyName")} error={errors.companyName} name="companyName" onChange={handleFieldChanged} placeholder={"companyPlaceholder"} />
                    <Input value={getValues("companyNumber")} mask="9999.999.999" error={errors.companyNumber} name="companyNumber" onChange={handleFieldChanged} placeholder={"taxNumberPlaceholder"} />
                </InputGroup>
                <PlacesAutoComplete value={getValues("companyAddress")} error={errors.companyAddress} name="companyAddress" onChange={handleFieldChanged} placeholder={"billingAddressPlaceholder"} />
                <BoxFooter>
                    <Button type="secondary" onClick={history.goBack}>
                        back
                    </Button>
                    <Stepper step={4} steps={5} />
                    <Button onClick={handleSubmit(handleButtonClicked)} disabled={Object.keys(errors).length > 0}>
                        next
                    </Button>
                </BoxFooter>
            </BoxContent>
        </BoxContainer>
    );
}

export default InvoicingInformation;