import Button from "../../../../../../components/button";
import BoxContent from "../../../../components/box-content";
import BoxContainer from "../../../../components/box-container";
import BoxFooter from "../../../../components/box-footer";
import BoxHeader from "../../../../components/box-header";
import Input from "../../../../components/input";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import InputGroup from "../../../../components/input-group";
import {useForm} from 'react-hook-form';
import Stepper from "../../../../components/stepper";
import IRouteComponentLanguageProps from '../../../../../../interfaces/IRouteComponentLanguageProps';
import Translation from "../../../../../../components/translation";
import { useTranslation } from "../../../../../../components/translation-context/TranslationContext";
import { useRequest } from "../../../../../../components/request-context";

const IncapacityInformation = (props: IRouteComponentLanguageProps) => {

    // Attributes
    const history = useHistory();
    const {request, setRequest} = useRequest();
    const {translate} = useTranslation();
    const { language } = props.match.params;
    const { register, handleSubmit, getValues, errors, setValue, clearErrors } = useForm({defaultValues: request});

    // Effects
    useEffect(() => {
        register("startDate", {required: true});
        register("endDate", {required: false});

        register("medicheckComment", {required: false});
        register("doctorComment", {required: false});

        clearErrors();
    }, [register]);

    // Functions
    function handleFieldChanged(event: any) {
        clearErrors(event.target.name);
        setValue(event.target.name, event.target.value);

        if(request) {
            //@ts-ignore
            request[event.target.name] = event.target.value;
            setRequest({...request});
        }
    }

    function handleButtonClicked() {
        history.push(`/${language}/one-shot/invoicing`);
    }

    return (
        <BoxContainer>
            <BoxHeader title={"injuryInfo"} subTitle={"oneShotCheckRequest"} />
            <BoxContent>
                <InputGroup>
                    <Input isClearable type="date" value={getValues("startDate")} error={errors.startDate} name="startDate" onChange={handleFieldChanged} placeholder={"startDatePlaceholder"} />
                    <Input isClearable type="date" minDate={getValues("startDate")} value={getValues("endDate")} error={errors.endDate} name="endDate" onChange={handleFieldChanged} placeholder={"endDatePlaceholder"} />
                </InputGroup>
                <div style={{display:'flex', width: '100%', flexDirection: 'column', marginTop: 25}}>
                    <p style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: 12, fontWeight: 300}}>
                        <Translation>
                            medicheckCommentPlaceholder 
                        </Translation>
                    </p>
                    <textarea value={getValues("medicheckComment")} name="medicheckComment" onChange={handleFieldChanged} placeholder={translate("medicheckCommentPlaceholder")} style={{marginTop: 5, height: 80, border: 'solid 1px #b3bac0', outline: 'none', resize: 'none', padding: 10, borderRadius: 8}} />
                
                    <p style={{color: 'rgba(0, 0, 0, 0.54)', fontSize: 12, fontWeight: 300, marginTop: 15}}>
                        <Translation>
                            doctorCommentPlaceholder
                        </Translation>
                    </p>
                    <textarea value={getValues("doctorComment")} name="doctorComment" onChange={handleFieldChanged} placeholder={translate("doctorCommentPlaceholder")} style={{marginTop: 5, height: 80, border: 'solid 1px #b3bac0', outline: 'none', resize: 'none', padding: 10, borderRadius: 8}} />
                </div>
                <BoxFooter>
                    <Button type="secondary" onClick={history.goBack}>
                        back
                    </Button>
                    <Stepper step={3} steps={5} />
                    <Button onClick={handleSubmit(handleButtonClicked)} disabled={Object.keys(errors).length > 0}>
                        next
                    </Button>
                </BoxFooter>
            </BoxContent>
        </BoxContainer>
    );
}

export default IncapacityInformation;