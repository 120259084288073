import { CSSProperties } from "react";

export default class RadioButtonViewStyle {

    base : CSSProperties = {
        width : 15,
        height : 15,
        backgroundColor : '#FFFFFF',
        borderRadius : '50%',
        padding : 3,
        cursor : 'pointer'
    }

    checked : CSSProperties = {
        width : '100%',
        height : '100%',
        borderRadius : '50%',
        backgroundColor : '#1AA5CF'
    }
}