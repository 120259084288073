import { parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

export function isNullOrEmpty(element: string) {
  return !element || element.trim().length === 0;
}

export function formatDate(date: string) {
  if (date && !date.includes('0001')) {
    let newDate = new Date(date);

    let year = newDate.getFullYear();

    let month = newDate.getMonth() + 1;
    let formattedMonth = '' + month;
    if (month < 10) {
      formattedMonth = '0' + month;
    }

    let day = newDate.getDate();
    let formattedDay = '' + day;
    if (day < 10) {
      formattedDay = '0' + day;
    }

    return `${formattedDay}/${formattedMonth}/${year}`;
  }

  return '-';
}

export function toLocalDateTime(dateString: string): string {
  if (!dateString) return '';

  try {
    const isoDate = new Date(`${dateString}Z`);
    const timeZone = 'Europe/Berlin';
    const zonedDate = utcToZonedTime(isoDate, timeZone);

    // Format the date to a readable format
    const formattedDate = format(zonedDate, 'yyyy-MM-dd HH:mm', { timeZone });

    return formattedDate;
  } catch (error) {
    console.error(`Error parsing date ${dateString}`, error);
    return '';
  }
}

export function formatDateAndTime(isoDateString: string): string {
  if (!isoDateString) return '';

  try {
    const parisTimeZone = 'Europe/Paris';
    let date = parseISO(isoDateString);

    if (!isoDateString.endsWith('Z')) {
      date = utcToZonedTime(date, 'UTC');
    }

    const formattedDate = format(date, 'dd/MM/yyyy HH:mm:ss', {
      timeZone: parisTimeZone,
    });

    return formattedDate;
  } catch (error) {
    console.error('Error parsing or formatting date:', error);
    return '';
  }
}

export function downloadCSV(csv: string, fileName: string) {
  fileName = `Export-${fileName}-Medicheck-${new Date().toLocaleDateString()}`;
  let blob = new Blob([`\uFEFF${csv}`], { type: 'text/csv;charset=utf-8;' });

  const link = document.createElement('a');
  link.download = `${fileName}.csv`;
  link.href = `${URL.createObjectURL(blob)}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
