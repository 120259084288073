import './style.css';
import React from "react";
import Success from './modules/success';
import { Route, Switch } from 'react-router-dom';
import DefaultChangePassword from './modules/default';

const ChangePassword = () => {
    return (
        <div className="change-password-container">
            <Switch>
                <Route exact path='/:language/change-password' component={DefaultChangePassword} />
                <Route exact path='/:language/change-password/success' component={Success} />
            </Switch>
        </div>
    );
}

export default ChangePassword;