import './style.css';
import React from "react";
import BoxContainerProps from './props';

const BoxContainer = ({children}: BoxContainerProps) => {
    return (
        <div className="box-container">
            {children}
        </div>
    )
}

export default BoxContainer;