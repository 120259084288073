import { Redirect, Route, Switch, useParams } from "react-router-dom";
import Footer from "./components/footer";
import CheckRequest from "./modules/check-request";
import Dashboard from "./modules/dashboard";
import ForgotPassword from "./modules/forgot-password";
import OneShot from "./modules/one-shot";
import SignIn from "./modules/sign-in";
import SignUp from "./modules/sign-up";
import "./style.css";
import React, { useEffect } from "react";
import { withRequest } from "../../components/request-context";
import { SuperiorContextProvider } from "../../components/superior-context";
import ChangePassword from "./modules/change-password";
import AlertContainer from "../../components/alert";
import Cookies from "universal-cookie";
import { useHistory } from "react-router";
import ResetPassword from "./modules/reset-password";
import { getAuth } from "firebase/auth";

const cookies = new Cookies();

const MyInterface = () => {
	// Attributes
	const history = useHistory();
	const { language }: any = useParams();
	const auth = getAuth();

	// Effects
	useEffect(() => {
		if (!process.env.REACT_APP_DEV_MODE && !history.location.pathname.includes("dashboard") && !history.location.pathname.includes("reset-password") && !history.location.pathname.includes("forgot-password")) {
			history.replace(`/${language}`);
			cookies.remove("ACCESS_TOKEN");
			cookies.remove("REFRESH_TOKEN");
		}

		// Sets the current language
		auth.languageCode = language;
	}, []);

	return (
		<SuperiorContextProvider>
			<div className="my-interface-container">
				<Switch>
					{/* Home */}
					<Route path="/:language/change-password" render={() => withFooter(<ChangePassword />)} />
					<Route path="/:language/check-request" render={(props) => withRequest(withFooter(<CheckRequest {...props} />), "CRF")} />
					<Route path="/:language/one-shot" render={(props) => withRequest(withFooter(<OneShot {...props} />), "OneShotForm")} />
					<Route path="/:language/dashboard" component={Dashboard} />

					{/* Auth */}
					<Route path="/:language/forgot-password" render={(props) => withFooter(<ForgotPassword {...props} />)} />
					<Route path="/:language/reset-password" render={() => withFooter(<ResetPassword />)} />
					<Route path="/:language/sign-in" render={(props) => withFooter(<SignIn {...props} />)} />
					<Route path="/:language/sign-up" render={(props) => withFooter(<SignUp {...props} />)} />

					<Route path="/:language" component={() => <Redirect to={`/${language}/sign-in`} />} />
				</Switch>
			</div>
			<AlertContainer />
		</SuperiorContextProvider>
	);
};

function withFooter(component: any) {
	return (
		<>
			{component}
			<Footer />
		</>
	);
}

export default MyInterface;
