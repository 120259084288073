import axios, { AxiosPromise } from "axios";

type SafeResult = {
    error? : string;
    data? : any;
}

export default class PaymentService {

    private static BASE_URL = `https://medicheck-backend.azurewebsites.net/v1/api/payments`;

    private static _createSafeResponse = async (promise : AxiosPromise<object>) : Promise<SafeResult> => {
        try {
            const response = await promise;

            if (response && response.data) {
                return { error : undefined, data : response.data };
            }

            return { error : 'Could not get any data back from the server', data : undefined };
        } catch (error) {
            return { error, data : undefined };
        }
    }

    static createPaymentIntentForAmount = (amount : number, request: any) => {
        const promise = axios.post(`${PaymentService.BASE_URL}`, { 
            amount, 
            oneShot:{...request, request: request},
            currency : 'eur' 
        });

        return PaymentService._createSafeResponse(promise);
    }
}