import React from "react";
import StripeInputWrapperViewProps from "./StripeInputWrapperViewProps";
import Input from "../../../../../../../../components/input";

const StripeInputWrapperView = (props: StripeInputWrapperViewProps) => {
    const { label, component, ...rest } = props;

    return (
        <Input placeholder={label} component={component} {...rest} />
    );
}

export default StripeInputWrapperView;