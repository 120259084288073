import React from "react";
import { CustomInputDateProps } from "./props";
import { useTranslation } from "../../../../../components/translation-context/TranslationContext";
import "../style.css";
import Translation from "../../../../../components/translation";
import { isNullOrEmpty } from "../../../../../utils";

const CustomInputDate = ({
  placeholder,
  onClickDatePicker,
  value,
  noError,
  error,
  name,
  noErrorLabel,
  computedClassName,
  tempValue,
  handleTempDateChange,
  handleBlur,
  ...props
}: CustomInputDateProps) => {
  const { translate } = useTranslation();

  return (
    <div className="input-container" onClick={onClickDatePicker}>
      <p
        className="input-title"
        style={{
          display: placeholder ? "inherit" : "none",
          opacity: isNullOrEmpty(value?.toString() || "") ? 0 : 1,
        }}
      >
        <Translation>{placeholder}</Translation>
      </p>
      <input
        style={{ cursor: "pointer" }}
        {...props}
        value={tempValue}
        className={computedClassName}
        autoComplete="off"
        name={name}
        placeholder={translate(placeholder ?? "")}
        onChange={handleTempDateChange}
        onBlur={handleBlur}
      />
      {!noError && (
        <p
          className="input-error"
          style={{ opacity: error && !noErrorLabel ? 1 : 0 }}
        >
          <Translation>requiredField</Translation>
        </p>
      )}
    </div>
  );
};

export default CustomInputDate;
