import React, { useState } from 'react';
import { createContext, useContext } from 'react';
import RequestDTO from '../../models/RequestDTO';
import { useSuperior } from '../superior-context';

interface IRequestContext {
	request: RequestDTO | undefined;
	setRequest: any;
	initialRequest: RequestDTO | undefined;
}

const RequestContext = createContext<IRequestContext>({
	request: undefined,
	setRequest: () => {},
	initialRequest: undefined,
});

const RequestContextProvider = ({ children, source }: any) => {
	//Attributes
	const { superior } = useSuperior();

	const initialRequest = {
		company: {
			id: superior?.companyId ?? '',
		},
		superior: {
			id: superior?.id ?? '',
			email: superior?.email ?? '',
			language: superior?.language ?? '',
			companyId: superior?.companyId ?? '',
		},
		endDate: '',
		location: 'OFFICE',
		source: source,
		startDate: '',
		timing: 'OPTIMIZED',
		doctorComment: '',
		medicheckComment: '',
		worker: {
			address: '',
			firstName: '',
			gender: undefined,
			id: '',
			languages: [],
			lastName: '',
			phoneNumber: '',
			function: '',
			key: '',
		},
		file: '',
		forceLaunch: true,
		authorizedLeave: true,
		certificateType: -1,
	};
	const [request, setRequest] = useState<any>(
		JSON.parse(JSON.stringify(initialRequest))
	);

	return (
		<RequestContext.Provider
			value={{
				request: request,
				setRequest: setRequest,
				initialRequest: JSON.parse(JSON.stringify(initialRequest)),
			}}
		>
			{children}
		</RequestContext.Provider>
	);
};

function useRequest() {
	return useContext(RequestContext);
}

function withRequest(
	component: any,
	source: 'CRF' | 'Interface' | 'OneShotForm'
) {
	return (
		<RequestContextProvider source={source}>{component}</RequestContextProvider>
	);
}

export { RequestContextProvider, useRequest, withRequest };
