import './style.css';
import React from "react";
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import IncapacityInformation from './modules/incapacity';
import Success from './modules/success';
import Worker from './modules/worker';
import Comments from './modules/comments';

const Request = () => {

    // Attributes
    const { language }: any = useParams();

    return (
        <div className="request-container" style={{height: window.innerHeight - 50}}>
            <Switch>
                <Route path='/:language/dashboard/request/worker' component={Worker} />
                <Route exact path='/:language/dashboard/request/incapacity' component={IncapacityInformation} />
                <Route exact path='/:language/dashboard/request/comments' component={Comments} />
                <Route exact path='/:language/dashboard/request/success' component={Success} />

                <Route path='/:language/dashboard/request' component={() => <Redirect to={`/${language}/dashboard/request/worker/selection`} />} />
            </Switch>
        </div>
    );
}

export default Request;