import axios from "axios";
import Service from "./Service";

async function post(refresh_token: string) {
	var details: any = {
		client_id: "client",
		grant_type: "refresh_token",
		refresh_token: refresh_token,
		client_secret: "511536EF-F270-4058-80CA-1C89C192F69A",
	};

	let formBody: any = [];

	for (let property in details) {
		let encodedKey = encodeURIComponent(property);
		let encodedValue = encodeURIComponent(details[property]);
		formBody.push(encodedKey + "=" + encodedValue);
	}

	formBody = formBody.join("&");

	try {
		return await axios.post(`${Service.IAM_URL}/connect/token`, formBody, { headers: { "Content-Type": "application/x-www-form-urlencoded", Accept: "application/json", Authorization: "bearer undefined" } }).then((elt) => elt.data);
	} catch (e) {
		return undefined;
	}
}

export default { post };
