import React from "react";
import PasswordColor from "./password-color";
import { PasswordLevelProps } from "./props";

const PasswordLevel = ({ value }: PasswordLevelProps) => {
	const superStrong = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[A-Za-z\d`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{12,}$/.test(value);
	const strong = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[A-Za-z\d`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{7,}$/.test(value);
	const medium = /(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{5,}/.test(value) || /(?=.*[A-Za-z])(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[A-Za-z`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{5,}/.test(value) || /(?=.*\d)(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[\d`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{5,}/.test(value) || /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[A-Za-z\d`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{5,}$/.test(value);

	const renderswitch = () => {
		switch (true) {
			case superStrong:
				return [<PasswordColor color={"#226F54"} />, <PasswordColor color={"#226F54"} />, <PasswordColor color={"#226F54"} />];
			case strong:
				return [<PasswordColor color={"#FEA82F"} />, <PasswordColor color={"#FEA82F"} />, <PasswordColor color={"#D0CCD0"} />];
			case medium:
				return [<PasswordColor color={"#dc576a"} />, <PasswordColor color={"#D0CCD0"} />, <PasswordColor color={"#D0CCD0"} />];

			default:
				return [<PasswordColor color={"#D0CCD0"} />, <PasswordColor color={"#D0CCD0"} />, <PasswordColor color={"#D0CCD0"} />];
		}
	};

	return <div style={{ display: "flex", flexDirection: "row" }}>{renderswitch()}</div>;
};

export default PasswordLevel;
