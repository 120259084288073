import React, { useEffect } from "react";
import BoxContainer from "../../../../../../components/box-container";
import { useHistory } from "react-router-dom";
import BoxSubTitle from "../../../../../../components/box-sub-title";
import BoxTitle from "../../../../../../components/box-title";
import MedicheckIllustration from './assets/illu_MC.png';
import IRouteComponentLanguageProps from '../../../../../../../../interfaces/IRouteComponentLanguageProps';
import Button from "../../../../../../../../components/button";
import { useRequest } from "../../../../../../../../components/request-context";

const Success = (props: IRouteComponentLanguageProps) => {

    // Attributes
    const history = useHistory();
    const {setRequest, initialRequest} = useRequest();
    const { language } = props.match.params;

    // Effects
    useEffect(() => {
        setRequest(initialRequest);
    }, []);

    // Functions 
    function handleButtonClicked() {
        history.push(`/${language}/dashboard/request`);
    }

    return (
        <div>
            <BoxContainer>
                <div style={{background: '#1AA5CF', borderBottom: '15px white solid', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8}}>
                    <div style={{padding: '25px 30% 25px 25px', height: 180, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                        <BoxSubTitle>
                            requestConfirmation
                        </BoxSubTitle>
                        <BoxTitle>
                            thanksConfirmation
                        </BoxTitle>
                        <BoxSubTitle>
                            youWillReceiveEmail
                        </BoxSubTitle>
                        <div style={{width: 'fit-content', transform: 'scale(0.9) translateX(-10px)'}}>
                            <Button type={"border-white"} onClick={handleButtonClicked}>
                                launchNewRequest
                            </Button>
                        </div>
                    </div>
                    <img src={MedicheckIllustration} style={{width: 85, marginLeft: 'auto', marginRight: 30, transform:'translateY(1px)'}} />
                </div>
            </BoxContainer>
        </div>
    );
}

export default Success;