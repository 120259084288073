import Axios from 'axios';
import RequestDTO from '../models/RequestDTO';
import Service from './Service'

async function post(companyId: string, request: RequestDTO | undefined) {
    if(request) {
        return Service.execute(Axios.post(`${Service.API_URL}/v1/api/companies/${companyId}/requests`, request, Service.config()));
    }
}

export default { post }