import './style.css';
import React from "react";
import DefaultSignUp from './modules/default';
import { Redirect, Route, Switch } from 'react-router-dom';
import EmployerSignUp from './modules/employer';
import IRouteComponentLanguageProps from '../../../../interfaces/IRouteComponentLanguageProps';
import SignUpSuccess from './modules/success';

const SignUp = (props: IRouteComponentLanguageProps) => {

    // Attributes
    const { language } = props.match.params;

    return (
        <div className="sign-up-container">
            <Switch>
                <Route exact path='/:language/sign-up' component={DefaultSignUp} />
                <Route exact path='/:language/sign-up/employer' component={EmployerSignUp} />
                <Route exact path='/:language/sign-up/success' component={SignUpSuccess} />
                <Route path='/:language/sign-up' component={() => <Redirect to={`/${language}/sign-up`} />} />
            </Switch>
        </div>
    );
}

export default SignUp;