import './style.css';
import React from "react";
import Introduction from './modules/introduction';
import WorkerInformation from './modules/worker';
import { Redirect, Route, Switch } from 'react-router-dom';
import IncapacityInformation from './modules/incapacity';
import InvoicingInformation from './modules/invoicing';
import PaymentInformation from './modules/payment';
import Success from './modules/success';
import IRouteComponentLanguageProps from '../../../../interfaces/IRouteComponentLanguageProps';

const OneShot = (props: IRouteComponentLanguageProps) => {
    const { language } = props.match.params;

    return (
        <div className="one-shot-container">
            <Switch>
                <Route exact path='/:language/one-shot/introduction' component={Introduction} />
                <Route exact path='/:language/one-shot/worker' component={WorkerInformation} />
                <Route exact path='/:language/one-shot/incapacity' component={IncapacityInformation} />
                <Route exact path='/:language/one-shot/invoicing' component={InvoicingInformation} />
                <Route exact path='/:language/one-shot/payment' component={PaymentInformation} />
                <Route exact path='/:language/one-shot/success' component={Success} />

                <Route path='/:language/one-shot' component={() => <Redirect to={`/${language}/one-shot/introduction`} />} />
            </Switch>
        </div>
    );
}

export default OneShot;