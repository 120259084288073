import { CSSProperties } from "react";

export default class PriceBreakdownViewStyle {

    category : CSSProperties = {
        display : 'flex',
        flexDirection : 'column',
        color: 'black'
    }

    label : CSSProperties = {
        fontFamily : 'Asap',
        fontWeight : 500,
        fontSize : 10,
        marginBottom : 5,
        color: 'black'
    }

    amount : CSSProperties = {
        fontFamily : 'Roboto',
        fontWeight : 300,
        fontSize : 18,
        color: 'black'
    }
}