import IProps from "../../../../interfaces/IProps";
import './style.css';
import React from "react";
import Translation from "../../../../components/translation";

const BoxTitle = ({children}: IProps) => {
    return (
        <h1 className="box-title">
            <Translation>
                {children}
            </Translation>
        </h1>
    )
}

export default BoxTitle;