import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import BusyIndicator from '../../../../../../components/busy-indicator';
import { useSuperior } from '../../../../../../components/superior-context';
import Translation from '../../../../../../components/translation';
import { useTranslation } from '../../../../../../components/translation-context/TranslationContext';
import CompanyCheckService from '../../../../../../network/CompanyCheckService';
import CompanyReportingService from '../../../../../../network/CompanyReportingService';
import { formatDate } from '../../../../../../utils';
import { Table } from '../../../../components/table';
import { TableColumn } from '../../../../components/table/props';
import { format } from 'date-fns';
import './style.css';

export function OngoingChecks() {
	const { superior } = useSuperior();
	const { translate } = useTranslation();
	const query = useQuery(
		`company-${superior?.companyId ?? ''}-checks`,
		refreshCompanyChecks
	);
	const { data: reportingOptions } = useQuery(
		`company-${superior?.companyId ?? ''}-reportings-0`,
		() => CompanyReportingService.get(superior?.companyId ?? '', 0),
		{ enabled: !!superior?.companyId }
	);

	// Little hack we had to do because the "order" of the fields were important.
	const columns = useMemo<Array<TableColumn<any>>>(() => {
		const cols: any[] = [];

		if (
			reportingOptions?.data?.find(
				(elt: any) => elt.option.name == 'Status' && elt.selected
			)
		) {
			cols.push({
				Header: translate('status'),
				accessor: (check: any) => translate(check.status),
			});
		}

		if (
			reportingOptions?.data?.find(
				(elt: any) => elt.option.name == 'Worker ID' && elt.selected
			)
		) {
			cols.push({
				Header: translate('matricule'),
				accessor: (check: any) => check.request.workerExternalId,
			});
		}

		if (
			reportingOptions?.data?.find(
				(elt: any) => elt.option.name == 'Worker' && elt.selected
			)
		) {
			cols.push({
				Header: translate('workerFullName'),
				accessor: (check: any) => check.request.worker.fullName,
			});
		}

		if (
			reportingOptions?.data?.find(
				(elt: any) => elt.option.name == 'Worker NISS' && elt.selected
			)
		) {
			cols.push({
				Header: translate('workerNISS'),
				accessor: (check: any) => check.request.workerNationalNumber,
			});
		}

		if (
			reportingOptions?.data?.find(
				(elt: any) => elt.option.name == 'Request Date' && elt.selected
			)
		) {
			cols.push({
				Header: translate('requestDate'),
				accessor: (check: any) =>
					format(new Date(check.request.creationDate), 'dd/MM/yyyy'),
			});
		}

		if (
			reportingOptions?.data?.find(
				(elt: any) => elt.option.name == 'Address' && elt.selected
			)
		) {
			cols.push({
				Header: translate('address'),
				accessor: (check: any) => check.request.worker.address,
			});
		}

		if (
			reportingOptions?.data?.find(
				(elt: any) => elt.option.name == 'Phone Number' && elt.selected
			)
		) {
			cols.push({
				Header: translate('gsm'),
				accessor: (check: any) => check.request.worker.phoneNumber,
			});
		}

		if (
			reportingOptions?.data?.find(
				(elt: any) => elt.option.name == 'Incapacity Start' && elt.selected
			)
		) {
			cols.push({
				Header: translate('incapacityStartDate'),
				accessor: (check: any) => formatDate(check.request.startDate),
			});
		}

		if (
			reportingOptions?.data?.find(
				(elt: any) => elt.option.name == 'Incapacity End' && elt.selected
			)
		) {
			cols.push({
				Header: translate('incapacityEndDate'),
				accessor: (check: any) => formatDate(check.request.endDate),
			});
		}

		if (
			reportingOptions?.data?.find(
				(elt: any) => elt.option.name == 'Check Timing' && elt.selected
			)
		) {
			cols.push({
				Header: translate('timing'),
				accessor: (check: any) => translate(check.request.timing.toLowerCase()),
			});
		}

		if (
			reportingOptions?.data?.find(
				(elt: any) => elt.option.name == 'Check Date' && elt.selected
			)
		) {
			cols.push({
				Header: translate('checkDate'),
				accessor: (check: any) => formatDate(check.checkDate),
			});
		}

		if (
			reportingOptions?.data?.find(
				(elt: any) => elt.option.name == 'Check Location' && elt.selected
			)
		) {
			cols.push({
				Header: translate('location'),
				accessor: (check: any) =>
					translate(check.request.location.toLowerCase()),
			});
		}

		if (
			reportingOptions?.data?.find(
			  (elt: any) => elt.option.name == "Prolongation Needed" && elt.selected
			)
		  ) {
			cols.push({
			  Header: translate("prolongationNeeded"),
			  accessor: (check: any) =>
				translate(check.request.plausibility.toLowerCase()),
			});
		  }

		if (
			reportingOptions?.data?.find(
				(elt: any) => elt.option.name == 'Actions' && elt.selected
			)
		) {
			cols.push({
				Header: translate('actions'),
				accessor: (check: any) => {
					return (
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							{(check.status === 'checksModificationProblem' ||
								check.status === 'requestsModificationProblem') &&
							check.state !== 4 &&
							check.state !== 7 ? (
								<a
									target='_blank'
									href={`https://forms.medicheck.io/${check.request.superior.language.toLowerCase()}/forms/gsm-problem?checkId=${
										check.id
									}&superiorId=${check.request.superior.id}`}
									style={{
										background: '#4691CD',
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'center',
										padding: '7.5px 10px',
										color: 'white',
										borderRadius: 8,
										fontWeight: 400,
										textAlign: 'center',
										cursor: 'pointer',
										textDecoration: 'none',
										margin: '0px 4px',
									}}
								>
									<Translation>solve</Translation>
								</a>
							) : (
								check.status !== 'checksFetchResults' &&
								check.status !== 'checksSendResults' &&
								check.status !== 'checksModificationProblem' &&
								check.status !== 'requestsModificationProblem' && (
									<>
										<motion.a
											whileHover={{ scale: 1.025 }}
											target='_blank'
											href={`https://forms.medicheck.io/${check.request.superior.language.toLowerCase()}/forms/edit-check?checkId=${
												check.id
											}&superiorId=${check.request.superior.id}`}
											style={{
												background: '#C0D3E0',
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
												padding: '7.5px 10px',
												color: 'white',
												borderRadius: 8,
												fontWeight: 400,
												textAlign: 'center',
												cursor: 'pointer',
												textDecoration: 'none',
												margin: '0px 4px',
											}}
										>
											<Translation>modify</Translation>
										</motion.a>
										<motion.a
											whileHover={{ scale: 1.025 }}
											target='_blank'
											href={`https://forms.medicheck.io/${check.request.superior.language.toLowerCase()}/forms/cancel-check?checkId=${
												check.id
											}&superiorId=${check.request.superior.id}`}
											style={{
												background: '#C0D3E0',
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'center',
												padding: '7.5px 10px',
												color: 'white',
												borderRadius: 8,
												fontWeight: 400,
												textAlign: 'center',
												cursor: 'pointer',
												textDecoration: 'none',
												margin: '0px 4px',
											}}
										>
											<Translation>cancel</Translation>
										</motion.a>
									</>
								)
							)}
						</div>
					);
				},
			});
		}

		return cols;
	}, [reportingOptions]);

	// Functions
	async function refreshCompanyChecks() {
		const checks = (await CompanyCheckService.get(superior?.companyId ?? ''))
			.data;
		let array: any[] = [];
		Object.keys(checks).forEach((elt) => {
			checks[elt].forEach((elt2: any) => {
				elt2.status = elt;
			});
			array = array.concat(checks[elt]);
		});

		return array.sort(
			(a, b) =>
				Number(new Date(b.request.creationDate)) -
				Number(new Date(a.request.creationDate))
		);
	}

	return (
		<div className='ongoing-container'>
			<div className='ongoing-header-container'>
				<h1>
					<Translation>onGoingCheckstitle</Translation>
				</h1>
			</div>
			<BusyIndicator query={query}>
				<Table
					tableName='ongoing-checks'
					rows={query.data ?? []}
					columns={columns}
					isExportable
				/>
			</BusyIndicator>
		</div>
	);
}
