import "./style.css";
import React, { useRef, useState } from "react";
import SelectionProps from "./props";
import Translation from "../../../../components/translation";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import { useTranslation } from "../../../../components/translation-context/TranslationContext";

const Selection = ({
  label,
  options,
  onChange,
  value,
  error,
  info,
}: SelectionProps) => {
  // Attributes
  const ref = useRef<any>();
  const infoRef = useRef<any>();
  const { translate } = useTranslation();
  const [showInfo, setShowInfo] = useState(false);
  useOutsideClick(ref, () => setShowInfo(false));

  // functions
  function renderInfo() {
    return (
      <div style={{ padding: 20, fontSize: 13 }}>
        <div
          dangerouslySetInnerHTML={{ __html: translate(`${info}Information`) }}
        />
      </div>
    );
  }

  return (
    <div className="selection-content">
      <p className="selection-label">
        <Translation>{label}</Translation>
        {info && (
          <>
            <div
              ref={infoRef}
              onClick={() => setShowInfo(true)}
              className="selection-info"
            >
              i
            </div>

            {showInfo && (
              <div
                ref={ref}
                style={{
                  top: infoRef.current.offsetTop,
                  left: infoRef.current.offsetLeft,
                  maxWidth: 350,
                  position: "absolute",
                  background: "white",
                  borderRadius: 8,
                  boxShadow: "0 3px 10px 0 rgba(0,0,0,0.2)",
                }}
              >
                {renderInfo()}
              </div>
            )}
          </>
        )}
      </p>
      <div className="selection-elements">
        {options.map((elt) => (
          <div
            data-cy={elt}
            onClick={() => onChange(elt)}
            className={`selection-element ${
              (Array.isArray(value) ? value.includes(elt) : value === elt)
                ? "active"
                : ""
            }`}
          >
            <Translation>{elt.toLowerCase()}</Translation>
          </div>
        ))}
      </div>
      <p className="selection-error" style={{ opacity: error ? 1 : 0 }}>
        <Translation>requiredField</Translation>
      </p>
    </div>
  );
};

export default Selection;
