import BoxTitle from '../box-title';
import BoxSubTitle from '../box-sub-title';
import BoxHeaderProps from './props';
import IlluMC from './assets/illu_MC.png';
import './style.css';
import React from "react";

const BoxHeader = ({title, subTitle}: BoxHeaderProps) => {
    return (
        <div className="box-header">
            <div>
                <BoxSubTitle>
                    {subTitle}
                </BoxSubTitle>
                <BoxTitle>
                    {title}
                </BoxTitle>
            </div>
            <div className="box-header-image-container">
                <img className="box-header-image" src={IlluMC} />
            </div>
        </div>
    )
}

export default BoxHeader;