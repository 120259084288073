import Lottie from 'lottie-react-web';
import React from 'react';

import loading from './assets/loading.json';
import { BusyIndicatorProps } from './props';
// style
import './style.css'

const BusyIndicator = (props: BusyIndicatorProps) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loading,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    if (props.queries) {
        if (props.queries.find((elt: any) => elt.isLoading)) {
            return (
                <div className='busy-indicator-div-container'>

                    <div className='busy-indicator-div'>
                        <Lottie options={defaultOptions}
                            height={200}
                            width={200}
                        />
                    </div>
                </div>
            )
        }
    }
    if (props.query) {
        if (props.query.isLoading) {
            return (
                <div className='busy-indicator-div-container'>

                    <div className='busy-indicator-div'>
                        <Lottie options={defaultOptions}
                            height={200}
                            width={200}
                        />
                    </div>
                </div>
            )
        }

        if (props.query.status !== "success" && props.query.status !== "idle") {
            return <div>
                {props.query.status}
            </div>
        }
    }

    return props.children;
}

export default BusyIndicator;