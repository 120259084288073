import Axios from 'axios';
import Service from './Service';

async function get(companyId: string, startDate: string, endDate: string) {
  return Service.execute(
    Axios.get(
      `${Service.API_URL}/v1/api/companies/${companyId}/archives?startDate=${startDate}&endDate=${endDate}`,
      Service.config()
    )
  );
}

export default { get };
