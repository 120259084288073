import Axios from 'axios';
import SuperiorDTO from '../models/SuperiorDTO';
import Service from './Service'

async function get() {
    return Service.execute(Axios.get(`${Service.API_URL}/v1/api/profiles`, Service.config()));
}

async function put(superior: SuperiorDTO) {
    return Service.execute(Axios.put(`${Service.API_URL}/v1/api/profiles`, superior, Service.config()));
}

export default { get, put }