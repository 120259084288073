import IProps from "../../../../interfaces/IProps";
import "./style.css";
import React from "react";

const BoxFooter = ({ children }: IProps) => {
  return (
    <div
      className="box-footer"
      style={!children.length ? { justifyContent: "flex-end" } : {}}
    >
      {children}
    </div>
  );
};

export default BoxFooter;
