import './style.css';
import React from "react";
import OopsIllustration from './assets/illu_oops.png';
import Translation from '../../components/translation';

const Error = () => {

    return (
        <div className="error-container">
            <img className="error-image" src={OopsIllustration} />
            <div className="error-description">
                <p>
                    <Translation>
                        dontHesitateFR
                    </Translation>
                </p>
                <p>
                    <Translation>
                        dontHesitateNL                    
                    </Translation>
                </p>
                <p>
                    <Translation>
                        dontHesitateEN                
                    </Translation>
                </p>
            </div>
        </div>
    );
}

export default Error;