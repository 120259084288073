import './style.css';
import React from "react";
import Translation from '../../../../components/translation';
import { useTranslation } from '../../../../components/translation-context/TranslationContext';

const Footer = () => {
    
    //Attributes
    const {language} = useTranslation();

    return (
        <div className="footer-container">
            <p className="footer-paragraph">
                <Translation>
                    footerContent
                </Translation>
            </p>
            <a className="footer-link" href={`https://privacy.medicheck.io/${language}`} target="_blank" rel="noopener noreferrer">
                <Translation>
                    privacyPolicy
                </Translation>
            </a>
        </div>
    )
}

export default Footer;