import BoxContent from '../../../../components/box-content';
import BoxContainer from '../../../../components/box-container';
import BoxHeader from '../../../../components/box-header';
import Input from '../../../../components/input';
import { useHistory } from 'react-router-dom';
import './style.css';
import React, { useState } from 'react';
import BoxFooter from '../../../../components/box-footer';
import Button from '../../../../../../components/button';
import SignUpIllustration from './assets/illu_sign_up.png';
import IRouteComponentLanguageProps from '../../../../../../interfaces/IRouteComponentLanguageProps';
import { useSuperior } from '../../../../../../components/superior-context';
import Translation from '../../../../../../components/translation';
import { motion } from 'framer-motion';

const DefaultSignUp = (props: IRouteComponentLanguageProps) => {
  // Attributes
  const history = useHistory();
  const { superior } = useSuperior();
  const { language } = props.match.params;
  const [email, setEmail] = useState(superior?.email ?? '');

  // Functions
  function handleEmailChanged(event: any) {
    setEmail(event?.target?.value);
    if (superior) {
      superior.email = event?.target?.value;
    }
    history.replace(`/${language}/sign-in`);
  }

  function handleValidateIdentity() {
    history.push(`/${language}/sign-up/employer`);
  }

  function handleLaunchOneShot() {
    history.push(`/${language}/one-shot?e-mail=${email}`);
  }

  return (
    <BoxContainer>
      <BoxHeader
        title={'yourCoordinatesTitle'}
        subTitle={'yourCoordinatesSubTitle'}
      />
      <BoxContent>
        <Input
          error
          noErrorLabel
          placeholder='emailPlaceholder'
          value={email}
          onChange={handleEmailChanged}
        />
        <motion.div
          initial={{ height: 130, opacity: 0 }}
          transition={{ type: 'keyframes' }}
          animate={{ opacity: 1, height: 'auto' }}
          className='sign-up-information-container'
          style={{ maxHeight: 130 }}
        >
          <motion.img
            initial={{ opacity: 0 }}
            transition={{ type: 'keyframes' }}
            animate={{ opacity: 1 }}
            src={SignUpIllustration}
          />
          <div style={{ padding: '20px 30px' }}>
            <p style={{ fontSize: 13, fontWeight: 600 }}>
              <Translation>unknownEmailTitle</Translation>
            </p>
            <p style={{ fontSize: 12, fontWeight: 300, marginTop: 10 }}>
              <Translation>unknownEmailParagraph</Translation>
            </p>
          </div>
        </motion.div>
        <BoxFooter>
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            transition={{ type: 'keyframes' }}
            animate={{ opacity: 1, height: 'auto' }}
            className='sign-up-footer-element'
            style={{ maxHeight: 100 }}
          >
            <p className='sign-up-footer-paragraph'>
              <Translation>yourCompanyIsAlreadyClient</Translation>
            </p>
            <Button
              onClick={handleValidateIdentity}
              style={{
                fontSize: 12,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              ValidateIdentity
            </Button>
          </motion.div>
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            transition={{ type: 'keyframes' }}
            animate={{ opacity: 1, height: 'auto' }}
            className='sign-up-footer-element'
            style={{ maxHeight: 100 }}
          >
            <p className='sign-up-footer-paragraph'>
              <Translation>yourCompanyIsNotClient</Translation>
            </p>
            <Button
              onClick={handleLaunchOneShot}
              style={{
                fontSize: 12,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              LaunchOneShot
            </Button>
          </motion.div>
        </BoxFooter>
      </BoxContent>
    </BoxContainer>
  );
};

export default DefaultSignUp;
