import IProps from '../../../../interfaces/IProps';
import './style.css';
import React from "react";

const InputGroup = ({children}: IProps) => {
    return (
        <div className="input-group">
            {children}
        </div>
    );
}

export default InputGroup;