import './style.css';
import React from "react";
import MenuElementProps from './props';
import { useHistory } from 'react-router-dom';
import Translation from '../../../../../../../../components/translation';

const MenuElement = ({children, isActive, route}: MenuElementProps) => {
    
    // Attributes
    const history = useHistory();

    // Functions
    function handleOnClick() {
        if(!isActive)
        {
            history.push(route);
        }
    }

    return (
        <div onClick={handleOnClick} className="menu-element">
            <div className="menu-element-label">
                <Translation>
                    {children}
                </Translation>
            </div>
            <div className="menu-element-indicator" style={{opacity: isActive ? 1 : 0}}/>
        </div>
    )
}

export default MenuElement;