import './style.css';
import React from "react";
import IProps from "../../../../interfaces/IProps";
import Translation from '../../../../components/translation';

const BoxParagraph = ({children}: IProps) => {
    return (
        <p className="box-paragraph">
            <Translation>
                {children}
            </Translation>
        </p>
    )
}

export default BoxParagraph;