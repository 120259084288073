import './style.css';
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Service from '../../network/Service';

const Alert = (props: any) => {

    // Attributes
    const { error, success } = props;

    return (
        <motion.div transition={{ duration: 0.4 }} initial={{ marginBottom: -50, opacity: 0 }} animate={{ marginBottom: 20, opacity: 1 }} className={error ? "alert-container" : "success-container"}>
            {error ? error : success}
        </motion.div>
    );
}

const AlertContainer = () => {

    const [errors, setErrors] = useState<string[]>([]);
    const [success, setSuccess] = useState<string>('');

    // Effects
    useEffect(() => {
        setInterval(() => {
            if (Service.errors.length > 0) {
                setErrors((prev) => {
                    return [Service.errors.pop() ?? ""].concat(prev);
                });

                setTimeout(() => {
                    setErrors((prev) => {
                        const newArray: string[] = [];
                        const reversed = prev.reverse();
                        reversed.pop();
                        return newArray.concat(reversed.reverse());
                    });
                }, 4000);
            }
            if (Service.success.length > 0) {
                setSuccess(Service.success[Service.success.length - 1]);
                setTimeout(() => setSuccess(""), 4000);
            }
        }, 1000);
    }, []);

    return (
        <div style={{ position: 'fixed', right: 20, bottom: 0 }}>
            {errors.map((elt, index) => <Alert key={index} error={elt} />)}
            {success && <Alert error={undefined} success={success} />}
        </div>
    );
}

export default AlertContainer;