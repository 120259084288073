import "./style.css";
import React from "react";
import DefaultSignIn from "./modules/default";
import InterfaceSignIn from "./modules/interface";
import { Redirect, Route, Switch } from "react-router-dom";
import IRouteComponentLanguageProps from "../../../../interfaces/IRouteComponentLanguageProps";
import SignInError from "./modules/error";

const SignIn = (props: IRouteComponentLanguageProps) => {
	// Attributes
	const { language } = props.match.params;

	return (
		<div className="sign-in-container">
			<Switch>
				<Route exact path="/:language/sign-in" component={DefaultSignIn} />
				<Route exact path="/:language/sign-in/error" component={SignInError} />
				<Route exact path="/:language/sign-in/interface" component={InterfaceSignIn} />
				<Route path="/:language/sign-in" component={() => <Redirect to={`/${language}/sign-in`} />} />
			</Switch>
		</div>
	);
};

export default SignIn;
