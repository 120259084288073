import Axios from "axios";
import SuperiorDTO from "../models/SuperiorDTO";
import Service from "./Service";

async function get(companyId: string) {
	return Service.execute(Axios.get(`${Service.API_URL}/v1/api/companies/${companyId}/superiors`, Service.config()));
}

async function post(companyId: string, superior: SuperiorDTO) {
	return Service.execute(Axios.post(`${Service.API_URL}/v1/api/companies/${companyId}/superiors`, superior, Service.config()));
}

async function put(companyId: string, superior: SuperiorDTO) {
	return Service.execute(Axios.put(`${Service.API_URL}/v1/api/companies/${companyId}/superiors`, superior, Service.config()));
}

async function remove(companyId: string, superiorId: string) {
	return Service.execute(Axios.delete(`${Service.API_URL}/v1/api/companies/${companyId}/superiors/${superiorId}`, Service.config()), false);
}

export default { get, post, put, remove };
