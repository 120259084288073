import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../../../../components/button";
import Translation from "../../../../../../components/translation";
import { isNullOrEmpty } from "../../../../../../utils";
import BoxContainer from "../../../../components/box-container";
import BoxContent from "../../../../components/box-content";
import BoxFooter from "../../../../components/box-footer";
import BoxHeader from "../../../../components/box-header";
import Input from "../../../../components/input";
import PasswordLevel from "./components/password-level";
import { getAuth, parseActionCodeURL, confirmPasswordReset } from "firebase/auth";
import IRouteComponentLanguageProps from "../../../../../../interfaces/IRouteComponentLanguageProps";
import { toast } from "react-toastify";
import { useTranslation } from "../../../../../../components/translation-context/TranslationContext";

const ResetPassword = (props: IRouteComponentLanguageProps) => {
	// Attributes
	const history = useHistory();
	const { translate } = useTranslation();
	const { language } = props.match.params;
	const [password, setPassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [confirmPassword, setConfirmPassword] = useState("");
	const auth = getAuth();

	// Functions
	function handlePasswordResetd(event: any) {
		setPassword(event?.target?.value);
	}

	function handleConfirmPasswordResetd(event: any) {
		setConfirmPassword(event?.target?.value);
	}

	async function handleButtonClicked() {
		setIsLoading(true);
		try {
			const code = parseActionCodeURL(window.location.href);

			// No valid code is available
			if (!code) {
				throw "error";
			}

			await confirmPasswordReset(auth, code?.code, password);

			history.push(`/${language}/reset-password/success`);
		} catch (error: any){
			switch(error.code){
				case "auth/expired-action-code":
				case "auth/invalid-action-code":
					toast(translate("code-error-invalid-reset-password"), {
						position: "bottom-right",
						type: "success",
						style: { background: "#f5365c" },
						bodyStyle: { color: "white" },
						hideProgressBar: true,
					});
					break;
				case "auth/weak-password":
					toast(translate("code-error-weak-reset-password"), {
						position: "bottom-right",
						type: "success",
						style: { background: "#f5365c" },
						bodyStyle: { color: "white" },
						hideProgressBar: true,
					});
					break;
				case "auth/user-not-found":
					toast(translate("code-error-user-not-found-reset-password"), {
						position: "bottom-right",
						type: "success",
						style: { background: "#f5365c" },
						bodyStyle: { color: "white" },
						hideProgressBar: true,
					});
					break;
				default:
					toast(translate("code-error-reset-password"), {
						position: "bottom-right",
						type: "success",
						style: { background: "#f5365c" },
						bodyStyle: { color: "white" },
						hideProgressBar: true,	
					});
			}
			
		}
		setIsLoading(false);
	}

	return (
		<BoxContainer>
			<BoxHeader title={"resetPasswordTitle"} subTitle={"resetPasswordSubTitle"} />
			<BoxContent>
				<div
					style={{
						background: "#F8DEDE",
						padding: 22,
						borderRadius: 8,
						marginBottom: 16,
						display: "flex",
						flexDirection: "row",
					}}
				>
					<img src={require("./assets/warning.png")} style={{ width: 38, height: 38, marginRight: 16 }} />
					<Translation>useSecurePassword</Translation>
				</div>
				<div style={{ marginTop: 20 }}>
					<Input type="password" value={password} onChange={handlePasswordResetd} placeholder={"passwordPlaceholder"} />
				</div>
				<div style={{ marginTop: 20 }}>
					<Input type="password" value={confirmPassword} onChange={handleConfirmPasswordResetd} placeholder={"confirmPasswordPlaceholder"} />
				</div>
				<PasswordLevel value={password} />
				<BoxFooter>
					<Button type={"secondary"} onClick={history.goBack}>
						back
					</Button>
					<Button isLoading={isLoading} onClick={handleButtonClicked} disabled={isNullOrEmpty(password) || isNullOrEmpty(confirmPassword) || password !== confirmPassword || !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[A-Za-z\d`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{12,}$/.test(password)}>
						next
					</Button>
				</BoxFooter>
			</BoxContent>
		</BoxContainer>
	);
};

export default ResetPassword;
