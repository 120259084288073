import './style.css';
import React from "react";
import DefaultForgotPassword from './modules/default';
import { Redirect, Route, Switch } from 'react-router-dom';
import Success from './modules/success';
import IRouteComponentLanguageProps from '../../../../interfaces/IRouteComponentLanguageProps';

const ForgotPassword = (props: IRouteComponentLanguageProps) => {

    // Attributes
    const { language } = props.match.params;

    return (
        <div className="forgot-password-container">
            <Switch>
                <Route exact path='/:language/forgot-password' component={DefaultForgotPassword} />
                <Route exact path='/:language/forgot-password/success' component={Success} />
                <Route path='/:language/sign-in' component={() => <Redirect to={`/${language}/sign-in`} />} />
            </Switch>
        </div>
    );
}

export default ForgotPassword;