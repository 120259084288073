import React, { useRef } from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import './style.css'
import useOutsideClick from "../../../../hooks/useOutsideClick";
import Suggestion from "./components/suggestion";
import PlacesAutocompleteProps from "./props";
import Input from "../input";

const PlacesAutoComplete = (props: PlacesAutocompleteProps) => {
    
    // Attributes
    const ref = useRef<any>();

    const { suggestions: { data }, setValue, clearSuggestions } = usePlacesAutocomplete({
        requestOptions: {},
        debounce: 300,
    });

    //Hooks
    useOutsideClick(ref, clearSuggestions);

    // Handlers
    function handleOnChange(event: any) {
        if(props.onChange) {
            props.onChange(event);
            setValue(event.target.value);
        }
    } 

    function handleSelect(suggestion: any) {
        if(props.onChange && suggestion) {
            // @ts-ignore
            props.onChange({target:{name: props.name, value: suggestion}});
            clearSuggestions();
        }
    };

    //Render
    return (
        <div ref={ref} className="autocomplete-container">
            <Input
                {...props}
                autoComplete={"nope"}
                onChange={handleOnChange}
            />

            <ul className="suggestion-ul" style={{ display: data.length > 0 ? 'block' : 'none' }}>
                {
                    data.map((suggestion) => <Suggestion suggestion={suggestion} handleSelect={handleSelect} />)
                }
            </ul>
        </div>
    );
};

export default PlacesAutoComplete;