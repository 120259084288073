import Button from "../../../../../../components/button";
import BoxContent from "../../../../components/box-content";
import BoxContainer from "../../../../components/box-container";
import BoxFooter from "../../../../components/box-footer";
import BoxHeader from "../../../../components/box-header";
import Input from "../../../../components/input";
import React, { useState } from "react";
import { isNullOrEmpty } from "../../../../../../utils";
import { useHistory } from "react-router-dom";
import IRouteComponentLanguageProps from "../../../../../../interfaces/IRouteComponentLanguageProps";
import SuperiorService from "../../../../../../network/SuperiorService";
import { useSuperior } from "../../../../../../components/superior-context";

const DefaultSignIn = (props: IRouteComponentLanguageProps) => {
	// Attributes
	const history = useHistory();
	const [isBusy, setIsBusy] = useState(false);
	const { superior, setSuperior } = useSuperior();
	const { language } = props.match.params;
	const [email, setEmail] = useState(superior?.email ?? "");

	// Functions
	function handleEmailChanged(event: any) {
		setEmail(event?.target?.value);
		if (superior) {
			superior.email = event?.target?.value;
		}
	}

	async function handleButtonClicked() {
		setIsBusy(true);
		const response = await SuperiorService.get(encodeURIComponent(email));
		if (response) {
			const superior = response.data;

			setSuperior(superior);
			if (superior) {
				switch (superior.requestForm) {
					case "OneShot":
						history.push(`/${superior.language.toLowerCase()}/one-shot`);
						break;
					case "CRF":
						history.push(`/${superior.language.toLowerCase()}/check-request`);
						break;
					default:
						if (superior.isVerified) {
							history.replace(`/${superior.language.toLowerCase()}/sign-in/interface`);
							break;
						}

						history.replace(`/${language}/sign-in/error`);
						break;
				}
			} else {
				history.replace(`/${language}/sign-up`);
			}
		} else {
			history.replace(`/${language}/sign-up`);
		}
		setIsBusy(false);
	}

	return (
		<BoxContainer>
			<BoxHeader title={"yourCoordinatesTitle"} subTitle={"yourCoordinatesSubTitle"} />
			<BoxContent>
				<Input autoFocus value={email} onChange={handleEmailChanged} placeholder={"emailPlaceholder"} data-cy="sign-in-email" />
				<BoxFooter>
					<Button isLoading={isBusy} onClick={handleButtonClicked} disabled={isNullOrEmpty(email)} dataCy="sign-in-next-button">
						next
					</Button>
				</BoxFooter>
			</BoxContent>
		</BoxContainer>
	);
};

export default DefaultSignIn;
