import React, { Component } from "react";
import PaymentFormViewProps from "./PaymentFormViewProps";
import StripeInputWrapperView from "./components/StripeInputWrapper/StripeInputWrapperView";
import PaymentFormViewStyle from "./PaymentFormViewStyle";
import { injectStripe, CardNumberElement, CardExpiryElement, CardCVCElement } from "react-stripe-elements";
import PriceBreakdownView from "../PriceBreakdown/PriceBreakdownView";
import RadioButtonView from "../RadioButton/RadioButtonView";
import PaymentFormViewState from './PaymentFormViewState';
import Translation from "../../../../../../../../components/translation";
import BoxFooter from "../../../../../../components/box-footer";
import Button from "../../../../../../../../components/button";
import Stepper from "../../../../../../components/stepper";
import PaymentService from "../../../../../../../../network/PaymentService";

class PaymentFormView extends Component<PaymentFormViewProps, PaymentFormViewState> {
    private static TVA_PERCENTAGE = 0.21;

    state = { agreedToTerms: false, isBusy: false };

    private _style = new PaymentFormViewStyle();

    private _calculateCheckTotal = () => {
        const { amount } = this.props;

        const tva = amount * PaymentFormView.TVA_PERCENTAGE;
        return [amount, tva, amount + tva];
    }

    private _onPaymentDetailsEntered = async () => {
        this.setState({isBusy: true});
        const response = await PaymentService.createPaymentIntentForAmount(this.props.amount, this.props.request);

        if (response.error) {
            alert('Problem with payment, please contact Medicheck.');
            this.setState({isBusy: false});
            return;
        }

        this._onPaymentIntentCreated(response.data);
    }


    private _onPaymentIntentCreated = async (paymentIntentSecret: string) => {
        if (!this.props.stripe) {
            this.setState({isBusy: false});
            return;
        }

        const response = await this.props.stripe.handleCardPayment(paymentIntentSecret);

        if (response.error) {
            alert('Problem processing payment, please contact Medicheck.');
            this.setState({isBusy: false});
            return;
        }
        this.setState({isBusy: false});
        this.props.history.push(`/${this.props.language}/one-shot/success`);
    }

    private _onLicenseAgreementClick = (agreedToTerms: boolean) => {
        this.setState({ agreedToTerms });
    }

    render() {
        const [base, tva, total] = this._calculateCheckTotal();
        return (
            <>
                <div style={this._style.form}>
                    <div style={this._style.content}>
                        {/* CARD INPUTS */}
                        <div className="payment-input-container" style={this._style.paymentForm}>
                            <StripeInputWrapperView label={this.props.translate('cardNumber')} component={CardNumberElementBetter} style={this._style.number} />
                            <StripeInputWrapperView label={this.props.translate('cardExpiry')} component={CardExpiryElementBetter} style={this._style.expiry} />
                            <StripeInputWrapperView label={this.props.translate('cardCVC')} component={CardCVCElementBetter} style={this._style.cvc} />
                        </div>

                        {/* PRICE BREAKDOWN */}
                        <div style={{ ...this._style.bubble, flexDirection: 'column' }}>
                            <div style={this._style.summaryWrapper}>
                                <div style={this._style.summaryText}>
                                    <Translation>
                                        orderSummary
                                    </Translation>
                                </div>
                            </div>

                            <div style={this._style.priceWrapper}>
                                <div style={this._style.checkAmountText}>
                                    <Translation>
                                        totalBreakdown
                                    </Translation>
                                </div>

                                <div style={{ display: 'flex', flex: 1, justifyContent: 'space-around', marginLeft: 15 }}>
                                    <PriceBreakdownView label='htva' amount={base} />
                                    <PriceBreakdownView label='tva' amount={tva} />
                                    <PriceBreakdownView label='checkTotal' amount={total} style={{ fontWeight: 400 }} />
                                </div>
                            </div>
                        </div>

                        {/* TERMS AGREENMENT */}
                        <div style={{ ...this._style.bubble }}>
                            <div style={{ marginRight: 15 }}>
                                <RadioButtonView onChange={this._onLicenseAgreementClick} />
                            </div>

                            <span>
                                <span style={this._style.termsAgreement}>
                                    <Translation>
                                        termsAgreementBefore
                                    </Translation>
                                </span>
                                <span onClick={() => window.open(`https://medichecktemplates.blob.core.windows.net/legal/${this.props.language.toUpperCase()}_General_Conditions_GDPR.pdf`)} style={this._style.termsAgreementLink}>
                                    <Translation>
                                        termsAgreementLink
                                    </Translation>
                                </span>
                                <span style={this._style.termsAgreement}>
                                    <Translation>
                                        termsAgreementAfter
                                    </Translation>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>


                <BoxFooter>
                    <Button type="secondary" onClick={this.props.history.goBack}>
                        back
                    </Button>
                    <Stepper step={5} steps={5} />
                    <Button isLoading={this.state.isBusy} onClick={this._onPaymentDetailsEntered} disabled={!this.state.agreedToTerms || !this.props.stripe}>
                        send
                    </Button>
                </BoxFooter>
            </>
        );
    }
}

class CardNumberElementBetter extends CardNumberElement {
    render() {
        return (
            <div style={{ width: 250, marginRight: 20 }}>
                <CardNumberElement {...this.props} style={{ base: { fontSize: "15px", color: '#405364', fontWeight: 300, fontFamily: 'Roboto, sans-serif' } }} />
            </div>
        )
    }
}

class CardExpiryElementBetter extends CardNumberElement {
    render() {
        return (
            <div style={{ width: 110, marginRight: 20 }}>
                <CardExpiryElement {...this.props} style={{ base: { fontSize: "15px", color: '#405364', fontWeight: 300, fontFamily: 'Roboto, sans-serif' } }} />
            </div>
        )
    }
}

class CardCVCElementBetter extends CardNumberElement {
    render() {
        return (
            <div style={{ width: 110 }}>
                <CardCVCElement {...this.props} style={{ base: { fontSize: "15px", color: '#405364', fontWeight: 300, fontFamily: 'Roboto, sans-serif' } }} />
            </div>
        )
    }
}

//@ts-ignore
export default injectStripe(PaymentFormView);