import Button from '../../../../../../../../../../components/button';
import BoxContent from '../../../../../../../../components/box-content';
import BoxContainer from '../../../../../../../../components/box-container';
import BoxFooter from '../../../../../../../../components/box-footer';
import BoxHeader from '../../../../../../../../components/box-header';
import Input from '../../../../../../../../components/input';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputGroup from '../../../../../../../../components/input-group';
import { useForm } from 'react-hook-form';
import Stepper from '../../../../../../../../components/stepper';
import IRouteComponentLanguageProps from '../../../../../../../../../../interfaces/IRouteComponentLanguageProps';
import { useRequest } from '../../../../../../../../../../components/request-context';
import Selection from '../../../../../../../../components/selection';
import PlacesAutoComplete from '../../../../../../../../components/places-autocomplete';

const WorkerInformation = (props: IRouteComponentLanguageProps) => {
	// Attributes
	const history = useHistory();
	const { request, setRequest } = useRequest();
	const { language } = props.match.params;
	const { register, handleSubmit, errors, setValue, getValues, clearErrors } =
		useForm({ defaultValues: request?.worker });

	// Effects
	useEffect(() => {
		register('firstName', { required: true });
		register('lastName', { required: true });
		register('address', { required: true });
		register('phoneNumber', { required: false });
		register('gender', { required: true });
		register('languages', {
			required: true,
			validate: (elt) => elt.length > 0,
		});
		register('function');
		register('externalId', { required: false });
		register('key', { required: false });

		clearErrors();
	}, [register]);

	useEffect(() => {
		// If key has value set externalId as key
		if (getValues('key')?.split('-')[0].trim()) {
			handleFieldChanged({
				target: {
					name: 'externalId',
					value: getValues('key')?.split('-')[0].trim(),
				},
			});
		}
	}, []);

	// Functions
	function handleGenderChanged(gender: 'MALE' | 'FEMALE') {
		if (request) {
			request.worker.gender = gender;
			setValue('gender', gender);
			clearErrors();
		}
	}

	function handleLanguageChanged(language: 'FR' | 'NL' | 'EN' | 'DE') {
		if (request) {
			if (request.worker.languages.includes(language)) {
				request.worker.languages = request.worker.languages.filter(
					(elt) => elt !== language
				);
			} else {
				request.worker.languages.push(language);
				setValue(
					'languages',
					request.worker.languages.reduce((elt) => elt)
				);
			}

			if (request.worker.languages.length === 0) {
				setValue('languages', []);
			} else {
				setValue(
					'languages',
					request.worker.languages.reduce((elt) => elt)
				);
			}
			clearErrors();
		}
	}

	function handleFieldChanged(event: any) {
		clearErrors(event.target.name);
		setValue(event.target.name, event.target.value);

		if (request) {
			//@ts-ignore
			request.worker[event.target.name] = event.target.value;
			setRequest({ ...request });
		}
	}

	function handleButtonClicked() {
		history.push(`/${language}/dashboard/request/incapacity`);
	}

	return (
		<BoxContainer>
			<BoxHeader title={'workerInfo'} subTitle={'requestForInterface'} />
			<BoxContent>
				<InputGroup>
					<Input
						value={getValues('firstName')}
						error={errors.firstName}
						name='firstName'
						onChange={handleFieldChanged}
						placeholder={'firstNamePlaceholder'}
					/>
					<Input
						value={getValues('lastName')}
						error={errors.lastName}
						name='lastName'
						onChange={handleFieldChanged}
						placeholder={'lastNamePlaceholder'}
					/>
				</InputGroup>
				<PlacesAutoComplete
					value={getValues('address')}
					error={errors.address}
					name='address'
					onChange={handleFieldChanged}
					placeholder={'addressPlaceholder'}
				/>
				<InputGroup>
					<Input
						value={getValues('phoneNumber')}
						error={errors.phoneNumber}
						name='phoneNumber'
						onChange={handleFieldChanged}
						placeholder={'gsmPlaceholder'}
					/>
					<Input
						value={getValues('function')}
						error={errors.function}
						name='function'
						onChange={handleFieldChanged}
						placeholder={'functionPlaceholder'}
					/>
				</InputGroup>
				<div className='selection-container'>
					<Selection
						label='genderPlaceholder'
						value={request?.worker.gender}
						options={['MALE', 'FEMALE']}
						onChange={handleGenderChanged}
						error={errors.gender}
					/>
					<Selection
						label='languagesPlaceholder'
						value={request?.worker.languages}
						options={['FR', 'NL', 'EN', 'DE']}
						onChange={handleLanguageChanged}
						error={errors.languages}
					/>
				</div>
				<div>
					<Input
						value={getValues('externalId')}
						error={errors.externalId}
						name='externalId'
						onChange={handleFieldChanged}
						placeholder={'matriculePlaceholder'}
					/>
				</div>
				<BoxFooter>
					<Button type='secondary' onClick={history.goBack}>
						back
					</Button>
					<Stepper step={2} steps={4} />
					<Button
						onClick={handleSubmit(handleButtonClicked)}
						disabled={Object.keys(errors).length > 0}
					>
						next
					</Button>
				</BoxFooter>
			</BoxContent>
		</BoxContainer>
	);
};

export default WorkerInformation;
