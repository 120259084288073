import * as React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./style.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { initializeApp } from "firebase/app";

Sentry.init({
	dsn: "https://aaddee17b302496fb695bdcda12246a9@o503027.ingest.sentry.io/5991317",
	integrations: [new Integrations.BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyDSoK4oYbNmzi80uQC459llvpAe6vuSvlA",
	authDomain: "medicheck-external-iam-af484.firebaseapp.com",
	projectId: "medicheck-external-iam-af484",
	storageBucket: "medicheck-external-iam-af484.appspot.com",
	messagingSenderId: "270130472743",
	appId: "1:270130472743:web:8cc183e67636bfab472b02",
};

// Initialize Firebase
initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById("root"));
