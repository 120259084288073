import './style.css';
import React from "react";

const Stepper = ({steps, step}: any) => {

    function renderSteps() {
        const arr : any[] = [];
        for(let i = 0; i < steps; i++)
        {
            arr.push(<div className={`step ${i < step ? "filled" : ""}`} />)
        }
        return arr;
    }

    return (
        <div className="stepper-container">
            {renderSteps()}
        </div>
    )
}

export default Stepper;