import './style.css';
import React from "react";
import Settings from './assets/settings.png';
import { useState } from 'react';
import ProfileButtonProps from './props';
import { useHistory } from 'react-router-dom';
import { useSuperior } from '../../../../../../../../components/superior-context';

const ProfileButton = ({isActive, route} : ProfileButtonProps) => {

    // Attributes
    const history = useHistory();
    const [hovered, setHovered] = useState(false);
    const {superior} = useSuperior();

    // Functions
    function handleOnClick() {
        history.push(route);
    }

    if(hovered || isActive) {
        return (
            <div onClick={handleOnClick}>
                <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className="profile-button-active">
                    <img className="profile-button-image" src={Settings} />
                </div>
                <div className="profile-button-indicator" style={{opacity: isActive ? 1 : 0}}/>
            </div>
        )
    }

    return (
        <div onMouseEnter={() => setHovered(true)} className="profile-button">
            {superior?.firstName ? superior.firstName[0] : ""}{superior?.lastName ? superior.lastName[0] : ""}
        </div>
    )
}

export default ProfileButton;