import { createContext, useContext } from 'react';

interface TranslationContextValue {
    language: string;
    translate: (key: string) => string;
    changeLanguage: (language: string) => void;
}

export const TranslationContext = createContext<TranslationContextValue>({
    language: '',
    translate: () => '',
    changeLanguage: () => {}
})

export function useTranslation(){
    return useContext(TranslationContext)
}