import IProps from "../../../../interfaces/IProps";
import './style.css';
import React from "react";
import Translation from "../../../../components/translation";

const BoxSubTitle = ({children}: IProps) => {
    return (
        <h2 className="box-sub-title">
            <Translation>
                {children}
            </Translation>
        </h2>
    )
}

export default BoxSubTitle;