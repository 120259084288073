import React, { useState } from 'react';
import { createContext, useContext } from 'react';
import SuperiorDTO from '../../models/SuperiorDTO';


interface ISuperiorContext {
    superior: SuperiorDTO | undefined;
    setSuperior: React.Dispatch<React.SetStateAction<SuperiorDTO>>;
}

const SuperiorContext = createContext<ISuperiorContext>({
    superior: undefined,
    setSuperior: () => {}
});

const SuperiorContextProvider = ({ children }: any) => {
    
    //Attributes
    const [superior, setSuperior] = useState<SuperiorDTO>({
        email: '',
        id: '',
        language: 'fr',
        companyId: ''
    });

    return (
        <SuperiorContext.Provider value={{ superior: superior, setSuperior: setSuperior }} >
            {children}
        </SuperiorContext.Provider>
    )
}

function useSuperior() {
    return useContext(SuperiorContext);
}

function withSuperior(component: any, source: 'CRF' | 'Interface' | 'OneShotForm') {

    return (
        <SuperiorContextProvider source={source}>
            {component}
        </SuperiorContextProvider>
    );
}

export {SuperiorContextProvider, useSuperior, withSuperior};
