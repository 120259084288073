import { motion } from 'framer-motion';
import React, { InputHTMLAttributes } from 'react';
import { useTranslation } from '../../../../components/translation-context/TranslationContext';
import "./style.css";

export function SearchInput(props: InputHTMLAttributes<HTMLInputElement>) {
	
	// Attributes
	const {translate} = useTranslation();
	const { placeholder = "search", ...rest } = props;

	return (
		<motion.div whileHover={{width: 300, border: '1px solid #1AA5CF' }} className="search-input-wrapper">
			<img className="search-icon" src={require("../../../../assets/search.png")} />

			<input type="text" placeholder={translate(placeholder)} {...rest} />
		</motion.div>
	);
}