import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PopUpProps from "./props";

export default function PopUp({
	children,
	isOpen,
	onClose = () => {},
	intensity = 4,
}: PopUpProps) {
	const [isRendered, setIsRenderer] = useState(false);

	useEffect(() => {
		setIsRenderer(true);
	}, []);

	if (!isRendered) {
		return <React.Fragment />;
	}

	return ReactDOM.createPortal(
		<AnimatePresence>
			{isOpen && (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					style={{
						backdropFilter: `blur(${intensity}px)`,
						left: 0,
						top: 0,
						position: "absolute",
						height: "100%",
						width: "100%",
						flex: 1,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						overflow: "hidden",
					}}
				>
					<motion.div
						initial={{ y: 20 }}
						animate={{ y: 0 }}
						exit={{ y: -20 }}
						style={{
							position: "absolute",
							zIndex: 50,
							flex: 1,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{children}
					</motion.div>
					<div
						onClick={onClose}
						style={{
							backdropFilter: `blur(${intensity}px)`,
							left: 0,
							top: 0,
							position: "absolute",
							height: "100%",
							width: "100%",
							background:
								"linear-gradient(0deg, rgba(24, 52, 94, 0.25), rgba(24, 52, 94, 0.25))",
						}}
					/>
				</motion.div>
			)}
		</AnimatePresence>,
		window.document.firstElementChild!
	);
}
