import IProps from "../../../../interfaces/IProps";
import './style.css';
import React from "react";

const BoxContent = ({children}: IProps) => {
    return (
        <div className="box-content">
            {children}
        </div>
    )
}

export default BoxContent;