import * as React from "react";
import { toast } from "react-toastify";
import Button from "../../../../../../../../components/button";
import PopUp from "../../../../../../../../components/pop-up";
import { useSuperior } from "../../../../../../../../components/superior-context";
import Translation from "../../../../../../../../components/translation";
import { useTranslation } from "../../../../../../../../components/translation-context/TranslationContext";
import SuperiorDTO from "../../../../../../../../models/SuperiorDTO";
import CompanySuperiorService from "../../../../../../../../network/CompanySuperiorService";
import DeleteSuperiorPopUpProps from "./props";

export default function DeleteSuperiorPopUp({ selectedUser, setSelectedUser, refetch }: DeleteSuperiorPopUpProps) {
	// Attributes
	const { superior } = useSuperior();
	const { translate } = useTranslation();
	const [isLoading, setIsLoading] = React.useState(false);

	// Functions
	async function handleAccessClicked(superior: SuperiorDTO) {
		setIsLoading(true);
		superior.isInterface = !superior.isInterface;
		const response = await CompanySuperiorService.remove(superior?.companyId ?? "", superior?.id ?? "");
		if (response) {
			toast(translate("superior-access-deleted"), {
				position: "bottom-right",
				type: "success",
				style: { background: "#52D8B0" },
				bodyStyle: { color: "white" },
				hideProgressBar: true,
			});

			await refetch();
			setSelectedUser(undefined);
		}
		setIsLoading(false);
	}

	return (
		<PopUp isOpen={!!selectedUser} onClose={() => setSelectedUser(undefined)}>
			<div
				style={{
					maxWidth: 400,
					padding: "30px 50px",
					width: "100%",
					borderRadius: 12,
					background: "white",
					display: "flex",
					flex: 1,
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img src={require("./assets/illu_red_warning.png")} style={{ height: 245, width: 245 }} />
				<div
					style={{
						marginTop: 40,
						color: "#EC5467",
						fontWeight: 400,
					}}
				>
					Hey {superior?.firstName}!
				</div>
				<div
					style={{
						marginTop: 10,
						marginBottom: 20,
						maxWidth: 200,
						textAlign: "center",
						fontWeight: 300,
						fontSize: 18,
					}}
				>
					<Translation>areYouSureYouWantToDelete</Translation>
				</div>

				<Button isLoading={isLoading} type="danger" onClick={() => handleAccessClicked(selectedUser!)}>
					deleteUser
				</Button>
				<div
					onClick={() => setSelectedUser(undefined)}
					style={{
						color: "#C0D3E0",
						marginTop: 15,
						textDecoration: "underline",
						textAlign: "center",
						fontSize: 16,
						fontWeight: 300,
						cursor: "pointer",
					}}
				>
					<Translation>OopsNoGoBack</Translation>
				</div>
			</div>
		</PopUp>
	);
}
