import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import BusyIndicator from "../../../../../../components/busy-indicator";
import Button from "../../../../../../components/button";
import { useSuperior } from "../../../../../../components/superior-context";
import Translation from "../../../../../../components/translation";
import { useTranslation } from "../../../../../../components/translation-context/TranslationContext";
import SuperiorDTO from "../../../../../../models/SuperiorDTO";
import CompanySuperiorService from "../../../../../../network/CompanySuperiorService";
import ProfileService from "../../../../../../network/ProfileService";
import Input from "../../../../components/input";
import { Raised } from "../../../../components/raised";
import { Table } from "../../../../components/table";
import DeleteSuperiorPopUp from "./components/delete-superior-pop-up";
import "./style.css";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const customStyles = {
	option: (provided: any) => ({
		...provided,
		fill: "#405364",
		fontWeight: 300,
		fontSize: 15,
	}),
	control: (provided: any) => ({
		...provided,
		width: "fill",
		border: "none",
		borderRadius: 0,
		borderBottom: `1px solid #929292`,
		boxShadow: "none",
		fontWeight: 300,
		padding: 0,
		"&:hover": {
			borderBottom: `1px solid #929292`,
		},
	}),
	dropdownIndicator: (base: any) => ({
		...base,
		color: "#405364",
		transform: "translate(2px)",
	}),
	indicatorSeparator: () => ({}),
	valueContainer: (provided: any) => ({
		...provided,
		padding: 0,
		outline: "none",
		fontWeight: 300,
	}),
	placeholder: (provided: any) => ({
		...provided,
		fill: "#405364",
		fontWeight: 300,
		fontSize: 15,
	}),
	singleValue: (provided: any, state: any) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";

		return { ...provided, opacity, transition, fontWeight: 300 };
	},
};

export function Settings() {
	// Attributes
	const history = useHistory();
	const { translate } = useTranslation();
	const { superior, setSuperior } = useSuperior();
	const { language }: any = useParams();
	const [rows, setRows] = useState([]);
	const [selectedUser, setSelectedUser] = useState<SuperiorDTO | undefined>();
	const [isLogOutClicked, setIsLogOutClicked] = useState(false);
	const query = useQuery(`company-${superior?.companyId ?? ""}-superiors`, refreshSuperiors, { enabled: superior?.requestForm == "InterfaceAdmin" });

	const columns = [
		{
			Header: translate("firstName"),
			accessor: "firstName",
		},
		{
			Header: translate("lastName"),
			accessor: "lastName",
		},
		{
			Header: translate("phoneNumber"),
			accessor: "phoneNumber",
		},
		{
			Header: translate("emailAddress"),
			accessor: "email",
		},
		{
			Header: translate("language"),
			accessor: "language",
		},
		{
			id: "actions",
			Header: "",
			accessor: (superior: any) => {
				return (
					<div onClick={() => setSelectedUser(superior)} className="settings-delete-button">
						<Translation>deleteAccess</Translation>
					</div>
				);
			},
		},
	];

	// Effect
	useEffect(() => {
		if (query.data && query.data?.length) {
			setRows(query.data.filter((elt: SuperiorDTO) => elt?.requestForm?.includes("Interface")));
		}
	}, [query.data]);

	// Functions
	async function refreshSuperiors() {
		const response = await CompanySuperiorService.get(superior?.companyId ?? "");
		return response.data;
	}

	function handleNewUserClicked() {
		history.push(`/${language}/dashboard/settings/new-user`);
	}

	function handleLogOutClicked() {
		setIsLogOutClicked(true);
	}

	function logOut() {
		cookies.remove("ACCESS_TOKEN");
		cookies.remove("REFRESH_TOKEN");

		history.replace(`/${language}/sign-in`);
	}

	function handleChangePassword() {
		history.push(`/${language}/change-password`);
	}

	async function handleCurrentUserUpdated(event: any) {
		let newSuperior = superior;
		if (newSuperior && superior) {
			if (event.target) {
				switch (event.target.name) {
					case "firstName":
						newSuperior = {
							...superior,
							firstName: event.target.value,
						};
						setSuperior({
							...superior,
							firstName: event.target.value,
						});
						break;
					case "lastName":
						newSuperior = {
							...superior,
							lastName: event.target.value,
						};
						setSuperior({
							...superior,
							lastName: event.target.value,
						});
						break;
					case "phoneNumber":
						newSuperior = {
							...superior,
							phoneNumber: event.target.value,
						};
						setSuperior({
							...superior,
							phoneNumber: event.target.value,
						});
						break;
				}
			} else {
				newSuperior = { ...superior, language: event.value };
				setSuperior({ ...superior, language: event.value });
			}

			await ProfileService.put(newSuperior);
		}
	}

	return (
		<main className="settings-container">
			{isLogOutClicked && (
				<motion.div
					initial={{
						position: "absolute",
						right: -50,
						top: -50,
						height: 100,
						width: 100,
						borderRadius: 50,
						background: "linear-gradient(90deg, rgba(25,165,206,1) 0%, rgba(41,185,195,1) 35%, rgba(26,165,207,1) 100%)",
						zIndex: 200,
					}}
					animate={{ scale: 40 }}
					onAnimationComplete={logOut}
				/>
			)}
			<h1>
				<Translation>userSettings</Translation>
			</h1>

			<Raised className="current-user-information">
				<Input disabled placeholder="emailPlaceholder" value={superior?.email} className="current-user-input" />
				<Input placeholder="firstNamePlaceholder" name="firstName" value={superior?.firstName} onChange={handleCurrentUserUpdated} className="current-user-input" />

				<Input name="lastName" placeholder="lastNamePlaceholder" value={superior?.lastName} onChange={handleCurrentUserUpdated} className="current-user-input" />

				<Input placeholder="gsmPlaceholder" name="phoneNumber" value={superior?.phoneNumber} onChange={handleCurrentUserUpdated} className="current-user-input" />
				<div style={{ width: 70 }}>
					<Select
						value={
							superior?.language
								? {
										value: superior.language,
										label: superior.language,
								  }
								: undefined
						}
						onChange={handleCurrentUserUpdated}
						styles={customStyles}
						name="language"
						placeholder={translate("languagesPlaceholder")}
						options={[
							{ value: "FR", label: "FR" },
							{ value: "NL", label: "NL" },
							{ value: "EN", label: "EN" },
						]}
					/>
				</div>
				<div className="current-user-actions">
					<div
						onClick={handleChangePassword}
						style={{
							background: "#4691CD",
							fontSize: 14,
							color: "white",
							cursor: "pointer",
							padding: "5px 15px",
							borderRadius: 8,
						}}
					>
						<Translation>changePassword</Translation>
					</div>
					<div
						onClick={handleLogOutClicked}
						style={{
							margin: "0px 10px",
							background: "#E6E9EF",
							fontSize: 14,
							color: "white",
							cursor: "pointer",
							padding: "5px 15px",
							borderRadius: 8,
						}}
					>
						<Translation>logout</Translation>
					</div>
				</div>
			</Raised>

			{superior?.requestForm?.includes("Admin") && (
				<>
					<div
						style={{
							width: 120,
							marginLeft: "auto",
							marginRight: 20,
							zIndex: 10,
						}}
					>
						<Button
							type={"border"}
							onClick={handleNewUserClicked}
							style={{
								fontSize: 12,
								padding: "10px 15px",
								height: "fit-content",
								width: 130,
								transform: "translateX(-40px)",
							}}
						>
							newUser
						</Button>
					</div>
					<div style={{ marginTop: -50 }}>
						<BusyIndicator query={query}>
							<Table tableName="user-settings" rows={rows} columns={columns} />
						</BusyIndicator>
					</div>
				</>
			)}
			<DeleteSuperiorPopUp refetch={query.refetch} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
		</main>
	);
}
