import './style.css'
import React from 'react';
import SuggestionProps from './props';

const Suggestion = (props: SuggestionProps) => {
    const {
        suggestion,
        handleSelect,
    } = props;

    const {
        id,
        structured_formatting: { main_text, secondary_text }
    } = suggestion;

    return (
        <div className="suggestion-li" key={id} onClick={() => handleSelect(`${main_text}, ${secondary_text}`)}>
            {main_text}, {secondary_text}
        </div>
    );
}

export default Suggestion;