import Button from "../../../../../../components/button";
import BoxContent from "../../../../components/box-content";
import BoxContainer from "../../../../components/box-container";
import BoxFooter from "../../../../components/box-footer";
import BoxHeader from "../../../../components/box-header";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import Stepper from "../../../../components/stepper";
import RequestService from "../../../../../../network/CompanyRequestService";
import IRouteComponentLanguageProps from '../../../../../../interfaces/IRouteComponentLanguageProps';
import { useRequest } from "../../../../../../components/request-context";
import { useSuperior } from "../../../../../../components/superior-context";
import Translation from "../../../../../../components/translation";
import { useTranslation } from "../../../../../../components/translation-context/TranslationContext";

const Comments = (props: IRouteComponentLanguageProps) => {

    // Attributes
    const history = useHistory();
    const { request } = useRequest();
    const { superior } = useSuperior();
    const { translate } = useTranslation();
    const { language } = props.match.params;
    const [isBusy, setIsBusy] = useState(false);
    const { getValues, register, handleSubmit, errors, setValue, clearErrors } = useForm({ defaultValues: request });

    // Effects
    useEffect(() => {
        register("startDate", { required: true });
        register("endDate", { required: false });

        clearErrors();
    }, [register]);

    // Functions
    function handleFieldChanged(event: any) {
        clearErrors(event.target.name);
        setValue(event.target.name, event.target.value);

        if (request) {
            //@ts-ignore
            request[event.target.name] = event.target.value;
        }
    }

    async function handleButtonClicked() {
        setIsBusy(true);
        if (request) {
            let requestDTO: any = {
                ...request
            }

            requestDTO.startDate = createDateAsUTC(new Date(request.startDate)).toISOString();

            if (request.endDate) {
                requestDTO.endDate = createDateAsUTC(new Date(request.endDate)).toISOString();
            }

            if(!requestDTO.worker.address.streetName){
                requestDTO.worker.address = {
                    streetName: request.worker.address
                };
            }

            const response = await RequestService.post(superior?.companyId ?? "", requestDTO);

            if (response) {
                history.push(`/${language}/check-request/success`);
            }
        }
        setIsBusy(false);
    }

    function createDateAsUTC(date: Date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    }

    return (
        <BoxContainer>
            <BoxHeader title={"addComment"} subTitle={"requestForCheckRequest"} />
            <BoxContent>
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                    <p style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 11, fontWeight: 300 }}>
                        <Translation>
                            medicheckCommentPlaceholder
                        </Translation>
                    </p>
                    <textarea value={getValues("medicheckComment")} name="medicheckComment" onChange={handleFieldChanged} placeholder={translate("medicheckCommentPlaceholder")} style={{ marginTop: 5, height: 80, border: 'solid 1px #b3bac0', outline: 'none', resize: 'none', padding: 10, borderRadius: 8 }} data-cy="box-comment-medicheck"/>

                    <p style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 11, fontWeight: 300, marginTop: 15 }}>
                        <Translation>
                            doctorCommentPlaceholder
                        </Translation>
                    </p>
                    <textarea value={getValues("doctorComment")} name="doctorComment" onChange={handleFieldChanged} placeholder={translate("doctorCommentPlaceholder")} style={{ marginTop: 5, height: 80, border: 'solid 1px #b3bac0', outline: 'none', resize: 'none', padding: 10, borderRadius: 8 }} data-cy="box-comment-doctor"/>
                </div>
                <BoxFooter>
                    <Button type="secondary" onClick={history.goBack}>
                        back
                    </Button>
                    <Stepper step={3} steps={3} />
                    <Button isLoading={isBusy} onClick={handleSubmit(handleButtonClicked)} disabled={Object.keys(errors).length > 0} dataCy="send-button">
                        send
                    </Button>
                </BoxFooter>
            </BoxContent>
        </BoxContainer>
    );
}

export default Comments;