import Button from "../../../../../../components/button";
import BoxContent from "../../../../components/box-content";
import BoxContainer from "../../../../components/box-container";
import BoxFooter from "../../../../components/box-footer";
import BoxHeader from "../../../../components/box-header";
import Input from "../../../../components/input";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import InputGroup from "../../../../components/input-group";
import { useForm } from 'react-hook-form';
import IRouteComponentLanguageProps from '../../../../../../interfaces/IRouteComponentLanguageProps';
import SuperiorService from "../../../../../../network/SuperiorService";

const EmployerSignUp = (props: IRouteComponentLanguageProps) => {

    // Attributes
    const history = useHistory();
    const { language } = props.match.params;
    const { register, handleSubmit, errors, setValue, getValues, clearErrors } = useForm();

    // Effects
    useEffect(() => {
        register("email", { required: true });
        register("firstName", { required: true });
        register("lastName", { required: true });
        register("phoneNumber", { required: true });
        register("companyName", { required: true });
    }, [register]);

    // Functions
    function handleFieldChanged(event: any) {
        clearErrors(event.target.name);
        setValue(event.target.name, event.target.value);


    }

    async function handleButtonClicked() {
        const response = await SuperiorService.post({
            email: getValues('email'),
            firstName: getValues('firstName'),
            lastName: getValues('lastName'),
            language: language,
            phoneNumber: getValues('phoneNumber'),
            companyName: getValues('companyName'),
        });

        if (response.data) {
            history.push(`/${language}/sign-up/success`);
        }
    }

    return (
        <BoxContainer>
            <BoxHeader title={"EmployerInformation"} subTitle={"newInscriptionToMedicheck"} />
            <BoxContent>
                <InputGroup>
                    <Input value={getValues("firstName")} error={errors.firstName} name="firstName" onChange={handleFieldChanged} placeholder={"firstNamePlaceholder"} />
                    <Input value={getValues("lastName")} error={errors.lastName} name="lastName" onChange={handleFieldChanged} placeholder={"lastNamePlaceholder"} />
                </InputGroup>
                <Input value={getValues("email")} error={errors.email} type="email" name="email" onChange={handleFieldChanged} placeholder={"emailPlaceholder"} />
                <Input value={getValues("phoneNumber")} error={errors.phoneNumber} type="phone" name="phoneNumber" onChange={handleFieldChanged} placeholder={"gsmPlaceholder"} />
                <Input value={getValues("companyName")} error={errors.companyName} name="companyName" onChange={handleFieldChanged} placeholder={"companyNamePlaceholder"} />
                <BoxFooter>
                    <Button type="secondary" onClick={history.goBack}>
                        back
                    </Button>
                    <Button onClick={handleSubmit(handleButtonClicked)} disabled={Object.keys(errors).length > 0}>
                        send
                    </Button>
                </BoxFooter>
            </BoxContent>
        </BoxContainer>
    );
}

export default EmployerSignUp;