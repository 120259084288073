import './style.css';
import * as React from 'react';
import Success from './modules/success';
import WorkerInformation from './modules/worker';
import IncapacityInformation from './modules/incapacity';
import Comments from './modules/comments';
import { Redirect, Route, Switch } from 'react-router-dom';
import IRouteComponentLanguageProps from '../../../../interfaces/IRouteComponentLanguageProps';

const CheckRequest = (props: IRouteComponentLanguageProps) => {
    const { language } = props.match.params;

    return (
        <div className="check-request-container">
            <Switch>
                <Route exact path='/:language/check-request/worker' component={WorkerInformation} />
                <Route exact path='/:language/check-request/incapacity' component={IncapacityInformation} />
                <Route exact path='/:language/check-request/comments' component={Comments} />
                <Route exact path='/:language/check-request/success' component={Success} />

                <Route path='/:language/check-request' component={() => <Redirect to={`/${language}/check-request/worker`} />} />
            </Switch>
        </div>
    );
}

export default CheckRequest;