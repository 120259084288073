import BoxContent from "../../../../components/box-content";
import BoxContainer from "../../../../components/box-container";
import BoxHeader from "../../../../components/box-header";
import Input from "../../../../components/input";
import { useHistory } from "react-router-dom";
import "./style.css";
import React, { useEffect, useState } from "react";
import SignUpIllustration from "./assets/illu_sign_up.png";
import IRouteComponentLanguageProps from "../../../../../../interfaces/IRouteComponentLanguageProps";
import { useSuperior } from "../../../../../../components/superior-context";
import Translation from "../../../../../../components/translation";
import { motion } from "framer-motion";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const SignInError = (props: IRouteComponentLanguageProps) => {
	// Attributes
	const history = useHistory();
	const { superior } = useSuperior();
	const { language } = props.match.params;
	const [email, setEmail] = useState(superior?.email ?? "");

	// Effects
	useEffect(() => {
		const auth = getAuth();
		sendPasswordResetEmail(auth, email);
	}, []);

	// Functions
	function handleEmailChanged(event: any) {
		setEmail(event?.target?.value);
		if (superior) {
			superior.email = event?.target?.value;
		}
		history.replace(`/${language}/sign-in`);
	}

	return (
		<BoxContainer>
			<BoxHeader title={"yourCoordinatesTitle"} subTitle={"yourCoordinatesSubTitle"} />
			<BoxContent>
				<Input error noErrorLabel placeholder="emailPlaceholder" value={email} onChange={handleEmailChanged} />
				<motion.div initial={{ height: 130, opacity: 0 }} transition={{ type: "keyframes" }} animate={{ opacity: 1, height: "auto" }} className="sign-up-information-container" style={{ maxHeight: 130 }}>
					<motion.img initial={{ opacity: 0 }} transition={{ type: "keyframes" }} animate={{ opacity: 1 }} src={SignUpIllustration} />
					<div style={{ padding: "20px 30px" }}>
						<p style={{ fontSize: 13, fontWeight: 600 }}>
							<Translation>iamChangedTitle</Translation>
						</p>
						<p style={{ fontSize: 12, fontWeight: 300, marginTop: 10 }}>
							<Translation>iamChangedDescription</Translation>
						</p>
					</div>
				</motion.div>
			</BoxContent>
		</BoxContainer>
	);
};

export default SignInError;
