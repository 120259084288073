import './style.css';
import React from "react";
import { Route, Switch } from "react-router-dom";
import WorkerInformation from './modules/information';
import WorkerList from './modules/selection'

const Worker = () => {

    return (
        <Switch>
                <Route exact path='/:language/dashboard/request/worker/information' component={WorkerInformation} />
                <Route exact path='/:language/dashboard/request/worker/selection' component={WorkerList} />
        </Switch>
    );
}

export default Worker;