import Button from "../../../../../../../../../../components/button";
import BoxContent from "../../../../../../../../components/box-content";
import BoxContainer from "../../../../../../../../components/box-container";
import BoxFooter from "../../../../../../../../components/box-footer";
import BoxHeader from "../../../../../../../../components/box-header";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Stepper from "../../../../../../../../components/stepper";
import IRouteComponentLanguageProps from '../../../../../../../../../../interfaces/IRouteComponentLanguageProps';
import { useQuery } from "react-query";
import CompanyWorkerService from "../../../../../../../../../../network/CompanyWorkerService";
import Select from 'react-select';
import WorkerDTO from "../../../../../../../../../../models/WorkerDTO";
import { useRequest } from "../../../../../../../../../../components/request-context";
import { useSuperior } from "../../../../../../../../../../components/superior-context";
import { useTranslation } from "../../../../../../../../../../components/translation-context/TranslationContext";

const customStyles = {
    option: (provided: any) => ({
      ...provided,
        fill: '#405364',
        fontWeight: 300,
        fontSize: 15
    }),
    control: (provided: any) => ({
        ...provided,
        width: "fill",
        border: 'none',
        borderRadius: 0,
        borderBottom: `1px solid #929292`,
        boxShadow: 'none',
        fontWeight: 300,
        padding: 0,
        '&:hover': {
            borderBottom: `1px solid #929292`
        }
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        color: '#405364',
        transform: 'translate(2px)'
    }),
    indicatorSeparator: () => ({}),
    valueContainer: (provided: any) => ({
        ...provided,
        padding: 0,
        outline: 'none',
        fontWeight: 300,
    }),
    placeholder: (provided: any) => ({
        ...provided,
        fill: '#405364',
        fontWeight: 300,
        fontSize: 15
    }),
    singleValue: (provided: any, state: any) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition, fontWeight: 300 };
    }
}

const WorkerList = (props: IRouteComponentLanguageProps) => {

    // Attributes
    const history = useHistory();
    const {request} = useRequest();
    const {superior} = useSuperior();
    const {translate} = useTranslation();
    const { language } = props.match.params;
    const [selectedWorker, setSelectedWorker] = useState(request?.worker.id);
    const query = useQuery(`${superior?.companyId ?? ""}-workers`, refreshWorkers);

    useEffect(() => {
        if(query.data) {
            if(query.data.length === 0) {
                handleButtonClicked();
            }
        }
    }, [query.data]);

    // Functions
    async function refreshWorkers() {
        const response = await CompanyWorkerService.get(superior?.companyId ?? "");
        return response.data;
    }

    function handleWorkerSelected(option: any) {
        const worker = query.data?.find((elt: WorkerDTO) => elt.id === option.value);
        setSelectedWorker(option.value);
        if(request) {
            request.worker = worker;
        }
    }

    function handleButtonClicked() {
        history.push(`/${language}/dashboard/request/worker/information`);
    }

    function handleNewClicked() {
        if(request) {
            request.worker = {
                address:'',
                firstName: "",
                gender : undefined,
                id : "",
                languages : [],
                lastName : "",
                phoneNumber: ""
            };
            history.push(`/${language}/dashboard/request/worker/information`);
        }
    }

    return (
        <BoxContainer>
            <BoxHeader title={"workerSelection"} subTitle={"requestForInterface"} />
            <BoxContent>
                <div>
                    <Select value={selectedWorker ? {value: request?.worker.id, label: request?.worker.fullName} : undefined} onChange={handleWorkerSelected} styles={customStyles} placeholder={translate("selectAWorker")} options={query?.data?.map((elt:WorkerDTO) => { return {value: elt.id, label: elt.fullName}}) ?? []} />
                    <div style={{display:'flex', marginTop: 20}}>
                        <Button onClick={handleNewClicked}>
                            requestNewWorker
                        </Button>
                    </div>
                </div>
                <BoxFooter>
                    <div style={{width: 125}} />
                    <Stepper step={1} steps={4} />
                    <Button onClick={handleButtonClicked} disabled={request?.worker.id === ""}>
                        next
                    </Button>
                </BoxFooter>
            </BoxContent>
        </BoxContainer>
    );
}

export default WorkerList;