import React, { useEffect } from "react";
import BoxContent from "../../../../components/box-content";
import BoxContainer from "../../../../components/box-container";
import BoxHeader from "../../../../components/box-header";
import { useHistory, useParams } from "react-router-dom";
import { Elements } from "react-stripe-elements";
import PaymentFormView from "./components/PaymentForm/PaymentFormView";
import { useRequest } from "../../../../../../components/request-context";
import { useTranslation } from "../../../../../../components/translation-context/TranslationContext";

const PaymentInformation = () => {
	// Attributes
	const { request, setRequest } = useRequest();
	const { translate } = useTranslation();
	const history = useHistory();
	const { language }: any = useParams();

	useEffect(() => {
		if (request) {
			request.location = "DOCTOR";
			setRequest({ ...request });
		}
	}, []);

	return (
		<BoxContainer>
			<BoxHeader title={"onlinePayment"} subTitle={"oneShotCheckRequest"} />
			<BoxContent>
				<Elements>
					<PaymentFormView translate={translate} language={language} request={request} history={history} routeProps={history} amount={150} />
				</Elements>
			</BoxContent>
		</BoxContainer>
	);
};

export default PaymentInformation;
