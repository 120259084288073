import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../../../../components/button";
import { useSuperior } from "../../../../../../components/superior-context";
import Translation from "../../../../../../components/translation";
import IRouteComponentLanguageProps from "../../../../../../interfaces/IRouteComponentLanguageProps";
import { isNullOrEmpty } from "../../../../../../utils";
import BoxContainer from "../../../../components/box-container";
import BoxContent from "../../../../components/box-content";
import BoxFooter from "../../../../components/box-footer";
import BoxHeader from "../../../../components/box-header";
import Input from "../../../../components/input";
import PasswordLevel from "./components/password-level";
import { getAuth, updatePassword, signInWithEmailAndPassword } from "@firebase/auth";

const ChangePassword = (props: IRouteComponentLanguageProps) => {
	// Attributes
	const history = useHistory();
	const { language } = props.match.params;
	const { superior } = useSuperior();
	const [password, setPassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [oldPassword, setOldPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	// Functions
	function handleOldPasswordChanged(event: any) {
		setOldPassword(event?.target?.value);
	}

	function handlePasswordChanged(event: any) {
		setPassword(event?.target?.value);
	}

	function handleConfirmPasswordChanged(event: any) {
		setConfirmPassword(event?.target?.value);
	}

	async function handleButtonClicked() {
		setIsLoading(true);

		if (superior) {
			let currentUser;
			try {
				currentUser = await signInWithEmailAndPassword(getAuth(), superior.email, oldPassword);
			} catch {
				toast("The current password is not correct.", {
					position: "bottom-right",
					type: "success",
					style: { background: "#f5365c" },
					bodyStyle: { color: "white" },
					hideProgressBar: true,
				});
			}

			// Unable to connect to the account
			if (!currentUser) {
				return;
			}

			try {
				await updatePassword(currentUser.user, password);

				history.push(`/${language}/change-password/success`);
			} catch {
				toast("We're unable to update your new password. Please verify if it's secure enough.", {
					position: "bottom-right",
					type: "success",
					style: { background: "#f5365c" },
					bodyStyle: { color: "white" },
					hideProgressBar: true,
				});
			}
		}
		setIsLoading(false);
	}

	return (
		<BoxContainer>
			<BoxHeader title={"changePasswordTitle"} subTitle={"changePasswordSubTitle"} />
			<BoxContent>
				<div
					style={{
						background: "#F8DEDE",
						padding: 22,
						borderRadius: 8,
						marginBottom: 16,
						display: "flex",
						flexDirection: "row",
					}}
				>
					<img src={require("./assets/warning.png")} style={{ width: 38, height: 38, marginRight: 16 }} />
					<Translation>useSecurePassword</Translation>
				</div>
				<Input type="password" value={oldPassword} onChange={handleOldPasswordChanged} placeholder={"oldPasswordPlaceholder"} />
				<div style={{ marginTop: 20 }}>
					<Input type="password" value={password} onChange={handlePasswordChanged} placeholder={"passwordPlaceholder"} />
				</div>
				<div style={{ marginTop: 20 }}>
					<Input type="password" value={confirmPassword} onChange={handleConfirmPasswordChanged} placeholder={"confirmPasswordPlaceholder"} />
				</div>
				<PasswordLevel value={password} />
				<BoxFooter>
					<Button type={"secondary"} onClick={history.goBack}>
						back
					</Button>
					<Button isLoading={isLoading} onClick={handleButtonClicked} disabled={isNullOrEmpty(password) || isNullOrEmpty(confirmPassword) || password !== confirmPassword || !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[A-Za-z\d`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{12,}$/.test(password)}>
						next
					</Button>
				</BoxFooter>
			</BoxContent>
		</BoxContainer>
	);
};

export default ChangePassword;
