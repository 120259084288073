import BoxContainer from "../../../../components/box-container";
import { useHistory } from "react-router-dom";
import BoxSubTitle from "../../../../components/box-sub-title";
import BoxTitle from "../../../../components/box-title";
import MedicheckIllustration from './assets/illu_MC.png';
import Button from "../../../../../../components/button";
import './style.css';
import React from "react";
import IRouteComponentLanguageProps from '../../../../../../interfaces/IRouteComponentLanguageProps';

const SignUpSuccess = (props: IRouteComponentLanguageProps) => {

    // Attributes
    const history = useHistory();
    const { language } = props.match.params;

    // Functions 
    function handleButtonClicked() {
        history.push(`/${language}/sign-in`);
    }

    return (
        <div>
            <BoxContainer>
                <div style={{ background: '#1AA5CF', borderBottom: '15px white solid', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '30px 30px 0px 30px', borderRadius: 8 }}>
                    <div style={{ height: 240, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <BoxSubTitle>
                            signUpSuccessSubTitle
                        </BoxSubTitle>
                        <BoxTitle>
                            signUpSuccessTitle
                        </BoxTitle>
                        <BoxSubTitle>
                            signUpSuccessParagraph
                        </BoxSubTitle>
                        <div className="forgot-password-success-button">
                            <Button type="success" onClick={handleButtonClicked}>
                                home
                            </Button>
                        </div>
                        <img src={MedicheckIllustration} style={{ background: '#1AA5CF', width: 85, marginLeft: 'auto' }} />
                    </div>
                </div>
            </BoxContainer>
        </div>
    );
}

export default SignUpSuccess;