import { Redirect, Route, Switch } from 'react-router-dom';
import IRouteComponentLanguageProps from '../../../../interfaces/IRouteComponentLanguageProps';
import Menu from './components/menu';
import { OngoingChecks } from './modules/ongoing-checks';
import Reports from './modules/reports';
import Request from './modules/request';
import './style.css';
import React, { useEffect, useState } from "react";
import { Settings } from './modules/settings';
import Certificates from './modules/certificates';
import { withRequest } from '../../../../components/request-context';
import NewUser from './modules/settings/new-user';
import { motion } from 'framer-motion';
import { useSuperior } from '../../../../components/superior-context';
import ProfileService from '../../../../network/ProfileService';

const Dashboard = (props: IRouteComponentLanguageProps) => {

    // Attributes
    const [animation, setAnimation] = useState(false);
    const { language } = props.match.params;
    const { superior, setSuperior } = useSuperior();

    // Effects
    useEffect(() => {
        async function refreshProfile() {
            const response = await ProfileService.get();

            if (superior) {
                superior.firstName = response?.data.firstName;
                superior.lastName = response?.data.lastName;
                superior.roles = response?.data.roles;
                superior.phoneNumber = response?.data.phoneNumber;
                superior.email = response?.data.userName;
                superior.companyId = response?.data.companyId;
                superior.requestForm = response?.data.requestForm;
                setSuperior({ ...superior });
            }
        }

        refreshProfile();
    }, [])

    return (
        <div className="dashboard-container" style={{ background: !animation ? 'linear-gradient(90deg, rgba(25,165,206,1) 0%, rgba(41,185,195,1) 35%, rgba(26,165,207,1) 100%)' : '#F5FAFF' }}>
            {!animation
                ?
                (
                    <motion.div onAnimationComplete={() => setAnimation(true)} animate={{ scale: 400, transition: { duration: 0.4 } }} style={{ height: 10, width: 10, borderRadius: 5, background: '#F5FAFF', position: 'absolute', bottom: 0, left: 0 }} />
                )
                :
                (
                    <>
                        <Menu {...props} />

                        <motion.div initial={{ y: 50 }} animate={{ y: 0 }}>
                            <Switch>

                                {/* Home */}
                                <Route path='/:language/dashboard/request' render={() => withRequest(<Request />, "Interface")} />
                                <Route path='/:language/dashboard/on-going' component={OngoingChecks} />
                                <Route path='/:language/dashboard/reports' component={Reports} />
                                <Route path='/:language/dashboard/certificates' component={Certificates} />
                                <Route exact path='/:language/dashboard/settings' component={Settings} />
                                <Route exact path='/:language/dashboard/settings/new-user' component={NewUser} />

                                <Route path='/:language/dashboard' component={() => <Redirect to={`/${language}/dashboard/request`} />} />
                            </Switch>
                        </motion.div>
                    </>
                )}

        </div>
    );
}

export default Dashboard;