import Button from '../../../../../../components/button';
import BoxContent from '../../../../components/box-content';
import BoxContainer from '../../../../components/box-container';
import BoxFooter from '../../../../components/box-footer';
import BoxHeader from '../../../../components/box-header';
import Input from '../../../../components/input';
import { useState } from 'react';
import { isNullOrEmpty } from '../../../../../../utils';
import { useHistory } from 'react-router-dom';
import './style.css';
import React from 'react';
import IRouteComponentLanguageProps from '../../../../../../interfaces/IRouteComponentLanguageProps';
import { useSuperior } from '../../../../../../components/superior-context';
import ProfileService from '../../../../../../network/ProfileService';
import Translation from '../../../../../../components/translation';
import { motion } from 'framer-motion';
import Cookies from 'universal-cookie';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import Service from '../../../../../../network/Service';
import { useTranslation } from '../../../../../../components/translation-context/TranslationContext';

const cookies = new Cookies();

const InterfaceSignIn = (props: IRouteComponentLanguageProps) => {
	// Attributes
	const auth = getAuth();
	const history = useHistory();
	const { language } = props.match.params;
	const [password, setPassword] = useState('');
	const { superior } = useSuperior();
	const [isBusy, setIsBusy] = useState(false);
	const [email, setEmail] = useState(superior?.email ?? '');
	const { translate } = useTranslation();

	// Functions
	function handleEmailChanged(event: any) {
		setEmail(event?.target?.value);
		if (superior) {
			superior.email = event?.target.value;
		}
		history.push(`/${language}/sign-in`);
	}

	function handlePasswordChanged(event: any) {
		setPassword(event?.target?.value);
	}

	function handleForgotPasswordClicked() {
		history.push(`/${language}/forgot-password`);
	}

	async function handleButtonClicked() {
		setIsBusy(true);
		try {
			let user = await signInWithEmailAndPassword(auth, email, password);

			if (user && user.user) {
				const token = await user.user.getIdToken();

				cookies.set('ACCESS_TOKEN', token);

				const response = await ProfileService.get();

				if (superior) {
					superior.firstName = response?.data.firstName;
					superior.lastName = response?.data.lastName;
					superior.roles = response?.data.roles;
					superior.phoneNumber = response?.data.phoneNumber;
					history.push(`/${language}/dashboard`);
				}
			}
		} catch {
			Service.errors.push(translate('invalid_username_or_password'));
		}
		setIsBusy(false);
	}

	return (
		<BoxContainer>
			<BoxHeader
				title={'yourCoordinatesTitle'}
				subTitle={'yourCoordinatesSubTitle'}
			/>
			<BoxContent>
				<Input
					autoFocus
					value={email}
					onChange={handleEmailChanged}
					placeholder={'emailPlaceholder'}
				/>
				<motion.div
					initial={{ height: 0, opacity: 0 }}
					transition={{ type: 'just' }}
					animate={{ height: 'fit-content', opacity: 1 }}
					style={{ marginTop: 20 }}
				>
					<Input
						type='password'
						value={password}
						onChange={handlePasswordChanged}
						placeholder={'yourPasswordPlaceholder'}
					/>
					<p
						onClick={handleForgotPasswordClicked}
						className='sign-in-forgot-password'
					>
						<Translation>forgotPasswordQuestion</Translation>
					</p>
				</motion.div>
				<div className='box-footer-interface'>
					<BoxFooter>
						<Button type='secondary' onClick={history.goBack}>
							back
						</Button>
						<Button
							isLoading={isBusy}
							onClick={handleButtonClicked}
							disabled={isNullOrEmpty(email) || isNullOrEmpty(password)}
						>
							login
						</Button>
					</BoxFooter>
				</div>
			</BoxContent>
		</BoxContainer>
	);
};

export default InterfaceSignIn;
