import React, { Component } from "react";
import Translation from "../../../../../../../../components/translation";
import PriceBreakdownViewProps from "./PriceBreakdownViewProps";
import PriceBreakdownViewStyle from "./PriceBreakdownViewStyle";

export default class PriceBreakdownView extends Component<PriceBreakdownViewProps> {

    private _style = new PriceBreakdownViewStyle();

    render() {
        const { label, amount, style } = this.props;
        return (
            <div style={this._style.category}>
                <div style={this._style.label}>
                    <Translation>
                        {label}
                    </Translation>
                </div>

                <span style={{ ...this._style.amount, ...style }}>
                    €{amount}
                </span>
            </div>
        );
    }
}