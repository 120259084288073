import Button from '../../../../../../../../components/button';
import BoxContent from '../../../../../../components/box-content';
import BoxContainer from '../../../../../../components/box-container';
import BoxFooter from '../../../../../../components/box-footer';
import BoxHeader from '../../../../../../components/box-header';
import Input from '../../../../../../components/input';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InputGroup from '../../../../../../components/input-group';
import { useForm } from 'react-hook-form';
import Stepper from '../../../../../../components/stepper';
import IRouteComponentLanguageProps from '../../../../../../../../interfaces/IRouteComponentLanguageProps';
import { useRequest } from '../../../../../../../../components/request-context';
import Selection from '../../../../../../components/selection';
import { useTranslation } from '../../../../../../../../components/translation-context/TranslationContext';
import Dropzone from 'react-dropzone';
import Translation from '../../../../../../../../components/translation';
import { toast } from 'react-toastify';

const IncapacityInformation = (props: IRouteComponentLanguageProps) => {
	// Attributes
	const history = useHistory();
	const { translate } = useTranslation();
	const { request, setRequest } = useRequest();
	const { language } = props.match.params;
	const { getValues, register, handleSubmit, errors, setValue, clearErrors } =
		useForm({ defaultValues: request });

	// Effects
	useEffect(() => {
		register('startDate', { required: true });
		register('endDate', { required: false });
		register('timing', { required: true });
		register('location', { required: true });
		clearErrors();
	}, [register]);

	// Functions
	function handleDrop(acceptedFiles: File[], _rejectedFiles: any) {
		handleFileChanged(acceptedFiles[0]);
	}

	function handleFileChanged(value: File) {
		return new Promise((_resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(value);
			reader.onload = () => {
				let base64 = reader.result as string;
				// @ts-ignore
				setRequest({ ...request, file: base64 });
			};
			reader.onerror = (error) => reject(error);
		});
	}

	function handleTimingChanged(value: 'OPTIMIZED' | 'IMMEDIATE') {
		if (!request?.endDate && value !== 'IMMEDIATE') {
			toast(translate('noEndDateGiven'), {
				position: 'bottom-right',
				type: 'error',
				style: { background: '#f5365c' },
				bodyStyle: { color: 'white' },
				hideProgressBar: true,
			});
			value = 'IMMEDIATE';
		} else if (
			!checkDates(request?.startDate, request?.endDate) &&
			value !== 'IMMEDIATE'
		) {
			toast(translate('endDateTooClose'), {
				position: 'bottom-right',
				type: 'error',
				style: { background: '#f5365c' },
				bodyStyle: { color: 'white' },
				hideProgressBar: true,
			});
			value = 'IMMEDIATE';
		}

		setValue('timing', value);
		if (request) {
			request.timing = value;
		}
		clearErrors();
	}

	function handleLocationChanged(value: 'OFFICE' | 'HOUSE' | 'TELECHECK') {
		if (!request?.worker.phoneNumber && value !== 'HOUSE') {
			toast(translate('noPhoneNumberGiven'), {
				position: 'bottom-right',
				type: 'error',
				style: { background: '#f5365c' },
				bodyStyle: { color: 'white' },
				hideProgressBar: true,
			});
			value = 'HOUSE';
		}

		setValue('location', value);
		if (request) {
			request.location = value;
		}
		clearErrors();
	}

	function checkDates(
		startDate: string | Date | null | undefined,
		endDate: string | Date | null | undefined
	) {
		if (startDate == null || startDate == undefined) {
			return false;
		}

		if (endDate == null || endDate == undefined) {
			return false;
		}

		const today = new Date();
		today.setHours(0, 0, 0, 0);

		endDate = new Date(endDate);
		endDate.setHours(0, 0, 0, 0);

		startDate = new Date(startDate);
		startDate.setHours(0, 0, 0, 0);

		if (
			Math.floor(
				Math.abs(today.getTime() - endDate.getTime()) / (1000 * 3600)
			) < 48
		) {
			return false;
		}

		// time difference
		let diffHours = Math.floor(
			Math.abs(startDate.getTime() - endDate.getTime()) / (1000 * 3600)
		);

		return diffHours >= 48;
	}

	function handleFieldChanged(event: any) {
		clearErrors(event.target.name);
		setValue(event.target.name, event.target.value);

		if (request) {
			//@ts-ignore
			request[event.target.name] = event.target.value;
		}
	}

	async function handleButtonClicked() {
		if (request) {
			if (!request?.endDate && request?.timing !== 'IMMEDIATE') {
				toast(translate('noEndDateGiven'), {
					position: 'bottom-right',
					type: 'error',
					style: { background: '#f5365c' },
					bodyStyle: { color: 'white' },
					hideProgressBar: true,
				});
				request.timing = 'IMMEDIATE';
			} else if (
				!checkDates(request?.startDate, request?.endDate) &&
				request?.timing !== 'IMMEDIATE'
			) {
				toast(translate('endDateTooClose'), {
					position: 'bottom-right',
					type: 'error',
					style: { background: '#f5365c' },
					bodyStyle: { color: 'white' },
					hideProgressBar: true,
				});
				request.timing = 'IMMEDIATE';
			} else if (!request?.worker.phoneNumber && request.location !== 'HOUSE') {
				toast(translate('noPhoneNumberGiven'), {
					position: 'bottom-right',
					type: 'error',
					style: { background: '#f5365c' },
					bodyStyle: { color: 'white' },
					hideProgressBar: true,
				});
				request.location = 'HOUSE';
			} else {
				history.push(`/${language}/dashboard/request/comments`);
			}
			clearErrors();
		}
	}

	return (
		<BoxContainer>
			<BoxHeader title={'injuryInfo'} subTitle={'requestForInterface'} />
			<BoxContent>
				<InputGroup>
					<Input
						isClearable
						type='date'
						value={getValues('startDate')}
						error={errors.startDate}
						name='startDate'
						onChange={handleFieldChanged}
						placeholder={'startDatePlaceholder'}
					/>
					<Input
						isClearable
						type='date'
						minDate={new Date()}
						value={getValues('endDate')}
						error={errors.endDate}
						name='endDate'
						onChange={handleFieldChanged}
						placeholder={'endDatePlaceholder'}
					/>
				</InputGroup>
				<div className='selection-container'>
					<Selection
						info='timing'
						label='checkTiming'
						options={['OPTIMIZED', 'IMMEDIATE']}
						onChange={handleTimingChanged}
						error={errors.timing}
						value={request?.timing}
					/>
					<Selection
						info='location'
						label='checkLocation'
						options={['OFFICE', 'HOUSE', 'TELECHECK']}
						onChange={handleLocationChanged}
						error={errors.location}
						value={request?.location}
					/>
				</div>

				<div className='dropzone-label'>
					<Translation>browseMedicalCertificate</Translation>
				</div>

				<Dropzone onDrop={handleDrop}>
					{({ getRootProps, getInputProps }) => {
						return (
							<div {...getRootProps()} style={{ outline: 'none' }}>
								<input
									{...getInputProps()}
									onChange={(value) =>
										handleFileChanged((value.target.files as FileList)[0])
									}
								/>
								<div className='dropzone-container'>
									{/* SWITCH THE IMAGE IF FILE HAS BEEN UPLOADED */}
									{request?.file != '' ? (
										<div className='dropzone-content'>
											<img
												src={require('./assets/uploaded.svg')}
												style={{
													width: 30,
													height: 30,
												}}
											/>
											<div
												style={{
													color: '#2FC3C0',
													fontWeight: 200,
													marginTop: 5,
												}}
											>
												<Translation>documentUploadSuccess</Translation>
											</div>
										</div>
									) : (
										<div className='dropzone-content'>
											<img
												src={require('./assets/upload.svg')}
												style={{
													width: 30,
													height: 30,
												}}
											/>
											<div
												style={{
													color: '#7F7F7F',
													fontWeight: 200,
													marginTop: 5,
												}}
											>
												<Translation>dragAndDropFileHere</Translation>
											</div>
										</div>
									)}
								</div>
							</div>
						);
					}}
				</Dropzone>
				<BoxFooter>
					<Button type='secondary' onClick={history.goBack}>
						back
					</Button>
					<Stepper step={3} steps={4} />
					<Button
						onClick={handleSubmit(handleButtonClicked)}
						disabled={Object.keys(errors).length > 0}
					>
						next
					</Button>
				</BoxFooter>
			</BoxContent>
		</BoxContainer>
	);
};

export default IncapacityInformation;
