import React from "react";
import { useTranslation } from "../translation-context/TranslationContext";

const Translation = (props: any) => {

    const { translate } = useTranslation();

    return (
        <>
            {translate(props.children)}
        </>
    )
}

export default Translation;