import React, { Component } from "react";
import RadioButtonViewStyle from "./RadioButtonViewStyle";
import RadioButtonViewState from "./RadioButtonViewState";
import RadioButtonViewProps from "./RadioButtonViewProps";

export default class RadioButtonView extends Component<RadioButtonViewProps, RadioButtonViewState> {

    state = { checked : false };

    private _style = new RadioButtonViewStyle();

    private _onChange = () => {
        const checked = !this.state.checked;
        this.setState({ checked });

        this.props.onChange(checked);
    }

    render() {
        const { checked } = this.state;
        return (
            <div style={this._style.base} onClick={this._onChange}>
                {checked &&
                    <div style={this._style.checked} />
                }
            </div>
        );
    }
}