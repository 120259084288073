import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../../../../components/button";
import IRouteComponentLanguageProps from "../../../../../../interfaces/IRouteComponentLanguageProps";
import { isNullOrEmpty } from "../../../../../../utils";
import BoxContainer from "../../../../components/box-container";
import BoxContent from "../../../../components/box-content";
import BoxFooter from "../../../../components/box-footer";
import BoxHeader from "../../../../components/box-header";
import Input from "../../../../components/input";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const ForgotPassword = (props: IRouteComponentLanguageProps) => {
	// Attributes
	const history = useHistory();
	const { language } = props.match.params;
	const [email, setEmail] = useState("");
	const [isBusy, setIsBusy] = useState(false);

	// Functions
	function handleEmailChanged(event: any) {
		setEmail(event?.target?.value);
	}

	async function handleButtonClicked() {
		setIsBusy(true);

		// change password
		const auth = getAuth();
		try {
			await sendPasswordResetEmail(auth, email);
			history.push(`/${language}/forgot-password/success`);
		} catch {
			toast("This user isn't known.", {
				position: "bottom-right",
				type: "error",
				style: { background: "#f5365c" },
				bodyStyle: { color: "white" },

				hideProgressBar: true,
			});
		}

		setIsBusy(false);
	}

	return (
		<BoxContainer>
			<BoxHeader title={"forgotPasswordTitle"} subTitle={"forgotPasswordSubTitle"} />
			<BoxContent>
				<Input value={email} onChange={handleEmailChanged} placeholder={"emailPlaceholder"} />
				<BoxFooter>
					<Button type={"secondary"} onClick={history.goBack}>
						back
					</Button>
					<Button isLoading={isBusy} onClick={handleButtonClicked} disabled={isNullOrEmpty(email)}>
						next
					</Button>
				</BoxFooter>
			</BoxContent>
		</BoxContainer>
	);
};

export default ForgotPassword;
