import Axios from 'axios';
import ProfileVerificationDTO from '../models/ProfileVerificationDTO';
import Service from './Service'

async function get(email: string) {
    return Service.execute(Axios.get(`${Service.API_URL}/v1/api/superiors?email=${email}`, Service.config()), false);
}

async function post(superior: ProfileVerificationDTO) {
    return Service.execute(Axios.post(`${Service.API_URL}/v1/api/superiors`, superior, Service.config()));
}

export default { get, post }