import React, { useEffect, useState } from 'react';
import { TranslationContext } from './TranslationContext';
import Translations from './assets/translations.json';
import { useParams } from 'react-router-dom';

export const TranslationContextProvider = ({ children }: any) => {

    // Attributes
    const {language : languageParam} : any = useParams();
    const [language, setLanguage] = useState('fr');

    // Effects
    useEffect(() => {
        changeLanguage(languageParam);
    }, [languageParam]);

    // Functions
    function changeLanguage(language: string) {
        setLanguage(language)
    }

    function translate(key: string) {

        if(Object.keys(Translations).includes(key)) {
            // @ts-ignore
            return Translations[key][language];
        }

        return `[${key}]`;
    }

    return (
        <TranslationContext.Provider value={{ language, translate, changeLanguage }}>
            {children}
        </TranslationContext.Provider>
    )
}

export function withTranslation(component: any) {
   return (
        <TranslationContextProvider>
            {component}
        </TranslationContextProvider>
   );
}