import { CSSProperties } from "react";

export default class PaymentFormViewStyle {

    form : CSSProperties = {
        display : 'flex', 
        flex : 1,
        flexDirection : 'column', 
        marginTop:20 
    }

    content : CSSProperties = {
        display : 'flex',
        flex : 1,
        flexDirection : 'column',
        justifyContent : 'space-between'
    }

    paymentForm : CSSProperties = {
        display : 'flex',
        width : '100%'
    }

    number : CSSProperties = {
        flex : 2,
        marginRight : 15
    }

    expiry : CSSProperties = {
        flex : 1,
        marginRight : 15
    }

    cvc : CSSProperties = {
        flex : 1
    }

    bubble : CSSProperties = {
        backgroundColor : '#ECF6F9',
        borderRadius : 10,
        padding : 20,
        display : 'flex',
        justifyContent : 'space-between',
        alignItems : 'center',
        marginTop: 20
    }

     summary : CSSProperties = {
        display : 'flex',
        flexDirection : 'column',
        marginRight : 15
    }

    summaryWrapper : CSSProperties = {
        width : '100%'
    }

    summaryText : CSSProperties = {
        fontFamily : 'Roboto',
        fontWeight : 500,
        fontSize : 15
    }

    priceWrapper : CSSProperties = {
        display : 'flex',
        width : '100%',
        flexWrap : 'wrap',
        justifyContent : 'space-between',
        marginTop : 10
    }

    checkAmountText : CSSProperties = {
        fontFamily : 'Roboto',
        fontWeight : 300,
        fontSize : 18,
        marginTop : 15
    }

    termsAgreement : CSSProperties = {
        fontFamily : 'Roboto',
        fontWeight : 300,
        fontSize : 12,
        lineHeight : '18px',
        color : '#2F2F2F'
    }

    termsAgreementLink: CSSProperties = {
        ...this.termsAgreement,
        textDecoration: 'underline',
        cursor: 'pointer'
    }
}