import './style.css';
import React from "react";
import Button from "../../../../../../components/button";
import BoxContent from "../../../../components/box-content";
import BoxContainer from "../../../../components/box-container";
import BoxFooter from "../../../../components/box-footer";
import BoxHeader from "../../../../components/box-header";
import { useHistory } from "react-router-dom";
import Stepper from "../../../../components/stepper";
import BoxParagraph from '../../../../components/box-paragraph';
import BoxSegmentTitle from '../../../../components/box-segment-title';
import Illustration from './assets/illu.png'
import IRouteComponentLanguageProps from '../../../../../../interfaces/IRouteComponentLanguageProps';

const Introduction = (props: IRouteComponentLanguageProps) => {

    // Attributes
    const history = useHistory();
    const { language } = props.match.params;

    // Functions
    function handleButtonClicked() {
        history.push(`/${language}/one-shot/worker`);
    }

    return (
        <BoxContainer>
            <BoxHeader title={"introMedicheck"} subTitle={"oneShotCheckRequest"} />
            <BoxContent>
                <div className="introduction-container">
                    <div style={{marginRight: 30}}>
                        <div className="introduction-segment">
                            <BoxSegmentTitle>
                                whatIsMedicheck
                            </BoxSegmentTitle>
                            <div style={{marginTop: 10}} />
                            <BoxParagraph>
                                whatIsMedicheckBody
                            </BoxParagraph>
                        </div>
                        <div style={{marginTop: 30}} />
                        <div className="introduction-segment">
                            <BoxSegmentTitle>
                                checkExplanation
                            </BoxSegmentTitle>
                            <div style={{marginTop: 10}} />
                            <BoxParagraph>
                                checkExplanationBody
                            </BoxParagraph>
                        </div>
                    </div>
                    <img src={Illustration} />
                </div>
                <BoxFooter>
                    <Button type="secondary" onClick={history.goBack}>
                        back
                    </Button>
                    <Stepper step={1} steps={5} />
                    <Button onClick={handleButtonClicked}>
                        next
                    </Button>
                </BoxFooter>
            </BoxContent>
        </BoxContainer>
    );
}

export default Introduction;