import './style.css';
import React from "react";
import IProps from "../../../../interfaces/IProps";
import Translation from '../../../../components/translation';

const BoxSegmentTitle = ({children}: IProps) => {
    return (
        <h3 className="box-segment-title">
            <Translation>
                {children}
            </Translation>
        </h3>
    )
}

export default BoxSegmentTitle;