import Axios from 'axios';
import Service from './Service';
import { format } from 'date-fns';

async function get(companyId: string, startDate: Date, endDate: Date) {
  return Service.execute(
    Axios.get(
      `${
        Service.API_URL
      }/v1/api/companies/${companyId}/certificates?startDate=${format(
        new Date(startDate),
        "yyyy-MM-dd'T'HH:mm:ss.SSS"
      )}Z&endDate=${format(new Date(endDate), "yyyy-MM-dd'T'HH:mm:ss.SSS")}Z`,
      Service.config()
    )
  );
}

export default { get };
