import ButtonProps from "./props";
import "./style.css";
import React from "react";
import Translation from "../translation";
import Lottie from "lottie-react-web";

const Button = ({
	children,
	disabled,
	isLoading,
	onClick,
	type = "primary",
	style,
	dataCy,
}: ButtonProps) => {
	// Functions
	function findClassName() {
		let className = `button ${type}`;

		if (disabled) {
			className += " disabled";
		} else if (isLoading) {
			className += " loading";
		} else {
			className += " active";
		}

		return className;
	}

	function handleClicked() {
		if (!disabled) {
			onClick();
		}
	}

	return (
		<div
			onClick={handleClicked}
			className={findClassName()}
			style={style}
			data-cy = {dataCy}
		>
			{isLoading ? (
				<Lottie
					options={{
						animationData: require("./assets/loading.json"),
					}}
				/>
			) : (
				<Translation>{children}</Translation>
			)}
		</div>
	);
};

export default Button;
