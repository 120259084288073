import "./style.css";
import React from "react";
import Success from "./modules/success";
import { Route, Switch } from "react-router-dom";
import DefaultResetPassword from "./modules/default";

const ResetPassword = () => {
	return (
		<div className="reset-password-container">
			<Switch>
				<Route exact path="/:language/reset-password" component={DefaultResetPassword} />
				<Route exact path="/:language/reset-password/success" component={Success} />
			</Switch>
		</div>
	);
};

export default ResetPassword;
