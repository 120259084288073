import './style.css';
import React from "react";
import Logo from './assets/logo.png';
import ProfileButton from './components/profile-button';
import MenuElement from './components/menu-element';
import { useLocation } from 'react-router-dom';
import IRouteComponentLanguageProps from '../../../../../../interfaces/IRouteComponentLanguageProps';
import { motion } from 'framer-motion';

const Menu = (props: IRouteComponentLanguageProps) => {

    // Attributes
    const { language } = props.match.params;
    const {pathname} = useLocation();

    return (
        <motion.div initial={{y: -55}} animate={{y: 0}} transition={{type: 'just'}} className="dashboard-menu">
            <img src={Logo} className="dashboard-menu-logo" />
            <div className="dashboard-menu-elements">
                <MenuElement isActive={pathname.includes('/request')} route={`/${language}/dashboard/request`}>
                    newCheckRequest
                </MenuElement>
                <MenuElement isActive={pathname.includes('/on-going')} route={`/${language}/dashboard/on-going`}>
                    onGoingChecks
                </MenuElement>
                <MenuElement isActive={pathname.includes('/reports')} route={`/${language}/dashboard/reports`}>
                    checkReports
                </MenuElement>
                <MenuElement isActive={pathname.includes('/certificates')} route={`/${language}/dashboard/certificates`}>
                    medicalCertificates
                </MenuElement>
            </div>
            <div className="dashboard-menu-logo">
                <ProfileButton isActive={pathname.includes('/settings')} route={`/${language}/dashboard/settings`} />
            </div>
        </motion.div>
    )
}

export default Menu;